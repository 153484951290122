import { Box, Card, CardMedia, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StyledTextButton } from '../../styles';

const WelcomeImages = () => {
  const mainBanner = useSelector((state) => state.homepage.mainBanner);

  const campaigns = useSelector((state) => state.homepage.campaigns);

  const { title, description, buttonTitle, buttonUrl, image } = mainBanner;

  return (
    <Grid
      container
      spacing={{ xs: 1, md: 2 }}
      sx={{
        maxWidth: 'xl',
        padding: { xs: 0, sm: '20px 40px 40px' },
      }}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            position: 'relative',
            borderRadius: '0',
            boxShadow: 'none',
          }}
        >
          <CardMedia component='img' image={image} width='100%' />

          <Box
            component='div'
            sx={{
              position: 'absolute',
              bottom: '20px',
              left: '20px',
              maxWidth: '50%',
            }}
          >
            <Typography gutterBottom variant='h1'>
              {title}
            </Typography>

            <Typography
              gutterBottom
              sx={{
                color: '#fff',
                display: { xs: 'none', md: 'block' },
              }}
            >
              {description}
            </Typography>

            <StyledTextButton
              size='small'
              href={buttonUrl}
              sx={{
                marginTop: '10px',
                color: '#fff',
                border: '2px solid #fff',
                paddingX: '20px',
              }}
            >
              {buttonTitle}
            </StyledTextButton>
          </Box>
        </Card>
      </Grid>

      {campaigns.map(({ image, url }, index) => (
        <Grid item key={index} component={Link} to={url} xs={12} sm={6}>
          <img src={image} width='100%' alt='' />
        </Grid>
      ))}
    </Grid>
  );
};

export default WelcomeImages;
