import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart, addProductToShoppingList } from '../../api/products';
import { getShoppingLists } from '../../api/user';
import { updateCart } from '../../state/cartSlice.js';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { StyledTextButton } from '../../styles';
import ProductCheckout from './ProductCheckout';
import ProductTable from './ProductTable/ProductTable';

const ProductDetails = ({ details, variants, smallAndMediumDevicesBreakpoint }) => {
  const { title, brand, datasheet } = details;

  const { description, reference, minQuantity, units } = variants[0];

  const [productsForCart, setProductsForCart] = useState(new Map());

  const [isDisabled, setIsDisabled] = useState(true);

  const [addToCartConfirmation, setAddToCartConfirmation] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [addToShoppingListConfirmation, setAddToShoppingListConfirmation] = useState(false);

  const [shoppingListErrorMessage, setShoppingListErrorMessage] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const cartProducts = Array.from(productsForCart.values());

    const isEmpty = !Boolean(cartProducts?.length);

    const noQuantity = cartProducts.every(
      ({ quantity: { units, boxes } }) => units === 0 && boxes === 0
    );

    const isDisabled = isEmpty || noQuantity;

    setIsDisabled(isDisabled);
  }, [productsForCart]);

  useEffect(() => {
    getShoppingLists()
      .then((lists) => {
        dispatch(setShoppingLists(lists));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function handleProductSelect(product) {
    const newProductsForCart = new Map(productsForCart);

    newProductsForCart.set(product.reference, product);

    setProductsForCart(newProductsForCart);
  }

  function addToCart() {
    const products = Array.from(productsForCart.values());

    const newProducts = products.filter(({ quantity: { boxes, units } }) => boxes > 0 || units > 0);

    addProductsToCart(newProducts)
      .then((cart) => {
        dispatch(updateCart(cart));

        setAddToCartConfirmation(true);
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setErrorMessage(msg);
          }
        }
      );
  }

  function addToShoppingList(selectShoppingList) {
    addProductToShoppingList(selectShoppingList, details._id)
      .then(() => {
        setAddToShoppingListConfirmation(true);
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setShoppingListErrorMessage(msg);
          }
        }
      );
  }

  return (
    <Box
      sx={{
        width: '100%',
        margin: '40px',
        marginTop: 0,
        flex: 1,
      }}
    >
      <Typography>{brand}</Typography>

      <Typography
        variant='h4'
        sx={{ fontWeight: '700', color: '#0b2a4a' }}
      >
        {title}
      </Typography>

      <Typography gutterBottom>{`Código: ${reference}`}</Typography>

      {minQuantity > 1 && (
        <Typography gutterBottom>
          A quantidade mínima para este artigo é {minQuantity} {units}.
        </Typography>
      )}

      {/* pass props and state setters */}
      <ProductTable
        variants={variants}
        selectForCart={handleProductSelect}
      />

      <Typography sx={{ fontSize: '10px', color: '#0b2a4a' }}>* Preços sem IVA</Typography>

      {/* pass props and state */}
      <ProductCheckout
        addToCart={addToCart}
        isDisabled={isDisabled}
        addToCartConfirmation={addToCartConfirmation}
        setAddToCartConfirmation={setAddToCartConfirmation}
        errorMessage={errorMessage}
        addToShoppingListConfirmation={addToShoppingListConfirmation}
        setAddToShoppingListConfirmation={setAddToShoppingListConfirmation}
        shoppingListErrorMessage={shoppingListErrorMessage}
        addToShoppingList={addToShoppingList}
        smallAndMediumDevicesBreakpoint={smallAndMediumDevicesBreakpoint}
      />

      {!!description && (
        <>
          <Typography
            variant='h5'
            gutterBottom
            sx={{ color: '#0b2a4a' }}
          >
            Informações adicionais
          </Typography>
          <Typography
            sx={{
              maxWidth: { xs: '100%', md: '75%' },
              marginBottom: 6,
            }}
          >
            {description}
          </Typography>
        </>
      )}

      <Typography
        variant='h5'
        gutterBottom
        sx={{ color: '#0b2a4a' }}
      >
        Informação Técnica
      </Typography>

      <Typography gutterBottom>
        Para mais informações sobre esta gama de produtos, por favor consulte a ficha técnica.
      </Typography>

      <StyledTextButton
        variant='contained'
        color='secondaryColorButton'
        startIcon={<BsFileEarmarkPdf />}
        disabled={!datasheet}
        href={datasheet}
        target='_blank'
      >
        Download Ficha Técnica
      </StyledTextButton>

      {!datasheet && <Typography>Entre em contacto connosco.</Typography>}
    </Box>
  );
};

export default ProductDetails;
