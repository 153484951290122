import { Container, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ProductCard from '../ProductCard';

const Highlights = () => {
  const products = useSelector((state) => state.homepage.products);

  return (
    <Container sx={{ marginTop: '60px' }}>
      <Typography variant='h5' gutterBottom sx={{ color: '#0b2a4a' }}>
        Destaques
      </Typography>

      <Typography>
        Os produtos mais procurados pelos nossos clientes.
      </Typography>

      {products && (
        <Grid
          container
          sx={{
            marginX: 0,
            marginY: '30px',
            gap: { xs: '6px', sm: '18px' },
            justifyContent: { xs: 'center', sm: 'normal' },
          }}
        >
          {products.map((product) => {
            const {
              variants: [variant],
            } = product;

            return (
              <Grid key={variant.reference} item xs={5.5} sm={2.7} md>
                <ProductCard product={{ ...variant, ...product }} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export default Highlights;
