import { createSlice } from '@reduxjs/toolkit';

const homepageSlice = createSlice({
  name: 'homepage',

  initialState: {
    mainBanner: {},
    campaigns: [],
    products: [],
    brands: [],
  },

  reducers: {
    setMainBanner: (state, action) => {
      state.mainBanner = action.payload;
    },

    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },

    setProducts: (state, action) => {
      state.products = action.payload;
    },

    setBrands: (state, action) => {
      state.brands = action.payload;
    },
  },
});

export const { setMainBanner, setCampaigns, setProducts, setBrands } =
  homepageSlice.actions;

export default homepageSlice.reducer;
