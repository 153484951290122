import axios from 'axios';
import { getHeaders } from './login';

const URL = `https://momelapi.bdca.pt/store`;

//  GET

function checkDeliveryOptions() {
  return axios
    .get(`${URL}/orders/delivery-methods`, getHeaders())
    .then(({ data }) => data);
}

// POST

function updateCartProducts(products) {
  return axios
    .post(`${URL}/cart/update`, { products }, getHeaders())
    .then(({ data: { cart } }) => cart);
}

function removeProductFromCart(reference, unitType) {
  return axios
    .post(`${URL}/cart/remove`, { reference, unitType }, getHeaders())
    .then(({ data: { cart } }) => cart);
}

function validateVoucher(voucherInput) {
  return axios
    .post(
      `${URL}/vouchers/verify`,
      { voucher_code: voucherInput },
      getHeaders()
    )
    .then(({ data: { voucher } }) => voucher);
}

function confirmOrder(orderDetails) {
  return axios
    .post(`${URL}/orders/create`, orderDetails, getHeaders())
    .then(({ data: { order } }) => order);
}

export {
  checkDeliveryOptions,
  updateCartProducts,
  removeProductFromCart,
  validateVoucher,
  confirmOrder,
};
