import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../../../api/user';
import { setAddresses } from '../../../../state/userSlice';
import NewAddressModal from '../../../UserAccount/AccountAddresses/NewAddressModal';

const DeliveryAtAddress = ({
  disabled,
  address,
  orderDetails,
  handleInputChange,
  onAddressChange,
}) => {
  const addresses = useSelector((state) => state.user.addresses);

  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    getUser()
      .then((user) => {
        const { addresses } = user;

        dispatch(setAddresses(addresses));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormControl margin='normal'>
        <InputLabel>Morada</InputLabel>

        <Select
          disabled={disabled}
          label='address'
          value={disabled ? '' : address}
          onChange={onAddressChange}
          sx={{
            backgroundColor: '#fff',
          }}
        >
          {addresses.map(({ street }, index) => (
            <MenuItem key={`${street}-${index}`} value={street}>
              {street}
            </MenuItem>
          ))}

          <MenuItem
            value='addAddress'
            onClick={() => setIsAddAddressOpen(true)}
          >
            Adicionar morada
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl margin='normal'>
        <TextField
          disabled={disabled}
          label='Observações'
          name='notes'
          value={orderDetails.notes}
          onChange={handleInputChange}
          variant='outlined'
          multiline
          minRows={3}
          sx={{
            backgroundColor: '#fff',
          }}
        />

        <NewAddressModal
          isOpen={isAddAddressOpen}
          setIsOpen={setIsAddAddressOpen}
        />
      </FormControl>
    </>
  );
};

export default DeliveryAtAddress;
