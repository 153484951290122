import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Cookies, CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import Login from "./components/Login/Login";
import PasswordRecovery from "./components/Login/PasswordRecovery";
import UserRegisterForm from "./components/Login/UserRegisterForm";
import AccountAccess from "./components/UserAccount/AccountAccess/AccountAccess";
import AccountAddresses from "./components/UserAccount/AccountAddresses/AccountAddresses";
import AccountInfo from "./components/UserAccount/AccountInfo/AccountInfo";
import AccountPurchases from "./components/UserAccount/AccountPurchases/AccountPurchases";
import AccountUsers from "./components/UserAccount/AccountUsers";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import AccountPage from "./routes/AccountPage";
import CampaignsPage from "./routes/Campaigns";
import CartPage from "./routes/CartPage";
import Catalogs from "./routes/Catalogs";
import CategoryPage from "./routes/CategoryPage";
import AboutUsPage from "./routes/FooterPages/AboutUsPage";
import ContactsPage from "./routes/FooterPages/ContactsPage";
import PrivacyPolicyPage from "./routes/FooterPages/PrivacyPolicyPage";
import TermsOfServicePage from "./routes/FooterPages/TermsOfServicePage";
import HomePage from "./routes/HomePage";
import LoginPage from "./routes/LoginPage";
import NotFoundPage from "./routes/NotFoundPage";
import ProductPage from "./routes/ProductPage";
import ProductsPage from "./routes/ProductsPage";
import SearchPage from "./routes/SearchPage";
import ShoppingListsPage from "./routes/ShoppingListsPage";
import store from "./state/store";
import { theme } from "./styles";

let persistor = persistStore(store);

const ProtectedRoute = ({ children }) => {
  const authentication = new Cookies().get("userToken");

  if (!authentication) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
};

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                <Route path="login" element={<LoginPage />}>
                  <Route index element={<Login />} />

                  <Route path="recuperar-palavra-passe" element={<PasswordRecovery />} />

                  <Route path="registo-utilizador" element={<UserRegisterForm />} />
                </Route>

                <Route element={<ProtectedRoute />}>
                  <Route path="/" element={<App />}>
                    <Route index element={<HomePage />} />

                    <Route path="pesquisa" element={<SearchPage />} />

                    <Route path="listas-de-compras" element={<ShoppingListsPage />} />

                    <Route path="carrinho" element={<CartPage />} />

                    <Route path="conta" element={<AccountPage />}>
                      <Route path="info" element={<AccountInfo />} />

                      <Route path="acesso" element={<AccountAccess />} />

                      <Route path="moradas" element={<AccountAddresses />} />

                      <Route path="utilizadores" element={<AccountUsers />} />

                      <Route path="encomendas" element={<AccountPurchases props={{ mode: "orders" }} />} />
                      <Route path="conta-corrente" element={<AccountPurchases props={{ mode: "receipts" }} />} />
                    </Route>

                    <Route path="categoria/:categorySlug" element={<Outlet />}>
                      <Route index element={<CategoryPage />} />

                      <Route path=":subcategoriesSlug" element={<ProductsPage />} />
                    </Route>

                    <Route path="produtos/:productSlug" element={<ProductPage />} />

                    <Route path="quemsomos" element={<AboutUsPage />} />

                    <Route path="contactos" element={<ContactsPage />} />

                    <Route path="termos" element={<TermsOfServicePage />} />

                    <Route path="privacidade" element={<PrivacyPolicyPage />} />

                    <Route path="campanhas" element={<CampaignsPage />} />

                    <Route path="catalogos" element={<Catalogs />} />

                    <Route path="*" element={<NotFoundPage />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
