import { Box, Typography } from '@mui/material';
import BudgetRequestForm from '../../components/Footer/BudgetRequestForm';
import StoreLocations from '../../components/Footer/StoreLocations';
import STORES from '../../constants/stores';

const ContactsPage = () => {
  return (
    <Box
      sx={{
        padding: '40px',
        backgroundColor: '#f7f7f7',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography gutterBottom variant='h4' fontWeight={700} color='#0b2a4a'>
        Contactos
      </Typography>

      <Typography sx={{ textAlign: 'center' }}>
        Precisa de ajuda? Temos ao seu dispor {STORES.length} lojas. <br />
        Encontre a loja Momel mais perto de si.
      </Typography>

      <StoreLocations stores={STORES} />

      <BudgetRequestForm />
    </Box>
  );
};

export default ContactsPage;
