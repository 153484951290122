import { Autocomplete, Grid, IconButton, MenuItem } from '@mui/material';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { getProducts } from '../../api/products';
import { StyledSearchTextfield } from '../../styles';

const SEARCH_TEXT = 'Ver todos: ';

const SEARCH_ID = 'search';

const SearchBar = () => {
  const [dropdownProducts, setDropdownProducts] = useState([]);

  const [shownProducts, setShownProducts] = useState([]);

  const [inputValue, setInputValue] = useState('');

  const navigate = useNavigate();

  // api request with limit = 5
  const getOptionsDebounced = debounce(async (input) => {
    const newProducts = await getProducts({ input }).then(
      ({ displayedProducts }) => displayedProducts
    );

    setDropdownProducts(newProducts);
  }, 500);

  useEffect(() => {
    const filteredProducts = dropdownProducts
      .filter(({ title, variants }) => {
        return (
          title.toLowerCase().includes(inputValue.toLowerCase()) ||
          variants.some(({ title }) =>
            title.toLowerCase().includes(inputValue.toLowerCase())
          ) ||
          variants.some(({ reference }) =>
            reference.toLowerCase().includes(inputValue.toLowerCase())
          )
        );
      })
      .slice(0, 5);

    const mappedProducts = filteredProducts.map(({ _id, title }) => ({
      label: title,
      id: _id,
    }));

    const searchFor = { label: `${SEARCH_TEXT}${inputValue}`, id: SEARCH_ID };

    const shownProducts = inputValue ? [...mappedProducts, searchFor] : [];

    setShownProducts(shownProducts);
  }, [dropdownProducts, inputValue]);

  function handleInputChange(e, newInputValue) {
    const replacedInputValue = newInputValue.replace(SEARCH_TEXT, '');

    setInputValue(replacedInputValue);

    if (replacedInputValue.length < 3) return;

    getOptionsDebounced(replacedInputValue);
  }

  function navigateToSearchPage(id) {
    if (id === SEARCH_ID && inputValue) {
      navigate({
        pathname: '/pesquisa',
        search: createSearchParams({
          query: inputValue,
        }).toString(),
      });
    }

    setInputValue('');
  }

  function handleClickOnItem(id) {
    navigateToSearchPage(id);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') navigateToSearchPage(SEARCH_ID);
  }

  function handleIconClick() {
    navigateToSearchPage(SEARCH_ID);
  }

  return (
    <Grid
      item
      xs={11}
      sm={4}
      sx={{
        height: '54px',
        margin: '10px 20px',
        padding: '0 10px',
        borderRadius: '40px',
        backgroundColor: '#0b2a4a4d',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Autocomplete
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={shownProducts}
        filterOptions={(x) => x}
        freeSolo
        fullWidth
        size='small'
        disableClearable
        renderInput={(params) => (
          <StyledSearchTextfield
            {...params}
            label='Digite o que procura'
            onKeyPress={handleKeyPress}
          />
        )}
        renderOption={(params, { id, label }) => (
          <MenuItem
            {...params}
            key={id}
            component={id !== SEARCH_ID ? Link : null}
            to={`produtos/${id}`}
            onClickCapture={() => handleClickOnItem(id)}
          >
            {label}
          </MenuItem>
        )}
      />

      <IconButton color='primary' onClick={handleIconClick}>
        <FiSearch size={24} />
      </IconButton>
    </Grid>
  );
};

export default SearchBar;
