import { Grid, Typography } from '@mui/material';
import React from 'react';

const ContactUs = () => {
	return (
		<Grid item xs={10} sm={2.5} md={2}>
			<Typography variant="h5" gutterBottom>
				Contacte-nos
			</Typography>

			<Typography gutterBottom>
				Travessa Momel nº269 <br /> 4600-216 Amarante <br /> Portugal
			</Typography>

			<Typography gutterBottom>
				<strong>Telf.</strong> +351 255 410 146
			</Typography>

			<Typography gutterBottom>
				<strong>Email</strong> profissionais@momel.pt
			</Typography>
		</Grid>
	);
};

export default ContactUs;
