import { Box } from '@mui/material';
import React, { useState } from 'react';
import { imageOnError } from '../../helpers';

const ProductImages = ({ images }) => {
  const [shownImage, setShownImage] = useState(0);
  const [firstImageExists, setFirstImageExists] = useState(true);

  return (
    <Box
      sx={{
        maxWidth: '350px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Box
        component='img'
        onError={(e) => {
          imageOnError(e);
          setFirstImageExists(false);
        }}
        src={images[shownImage]}
        width='100%'
        alt='imagem do produto'
      />

      {firstImageExists && images.length > 1 && (
        <Box
          sx={{
            height: '100px',
            display: 'flex',
            gap: '10px',
            overflowX: 'auto',
          }}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              component='img'
              src={image}
              hidden
              onLoad={(e) => {
                e.target.hidden = false;
              }}
              onError={(e) => {
                e.target.remove();
              }}
              onClick={() => setShownImage(index)}
              sx={{
                '&:hover': {
                  border: '1px solid #0b2a4a',
                  cursor: 'pointer',
                },
              }}
              alt='imagem do produto'
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ProductImages;
