import axios from 'axios';
import { getHeaders } from './login';
import { mapFilters, mapProduct } from './mapApiInterface';

const URL = `https://momelapi.bdca.pt/store`;

//  GET

function getProduct(id) {
  return axios
    .get(`${URL}/products/${id}`, getHeaders())
    .then(({ data: { data: product } }) => mapProduct(product));
}

function getProducts({
  categorySlug = '',
  sortBy = 'date:desc',
  filters = [],
}) {
  const params = { category: categorySlug, sortBy };

  const paramsString = new URLSearchParams(params).toString();

  const filtersString = new URLSearchParams(filters).toString();

  return axios
    .get(`${URL}/products?${paramsString}&${filtersString}`, getHeaders())
    .then(({ data: { products, filters } }) => {
      const displayedProducts = products.map((product) => mapProduct(product));

      const displayedFilters = mapFilters(filters);

      return { displayedFilters, displayedProducts };
    });
}

function getSearchedProducts(input, sortBy = 'name:asc') {
  return axios
    .get(`${URL}/search?query=${input}&sortBy=${sortBy}`, getHeaders())
    .then(({ data: { products } }) =>
      products.map((product) => mapProduct(product))
    );
}

// POST

function addProductsToCart(newProducts) {
  return axios
    .post(`${URL}/cart/add`, { products: newProducts }, getHeaders())
    .then(({ data: { cart } }) => cart.cart_items);
}

function addProductToShoppingList(listId, product_id) {
  return axios
    .post(`${URL}/shopping-lists/${listId}/add`, { product_id }, getHeaders())
    .then(({ data: { lists } }) => lists);
}

export {
  getProduct,
  getProducts,
  getSearchedProducts,
  addProductsToCart,
  addProductToShoppingList,
};
