import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSearchedProducts } from '../api/products';
import Loading from '../components/Loading';
import ProductCard from '../components/ProductCard';
import ProductSortBy from '../components/ProductSortBy';
import { setProducts } from '../state/productsSlice';

const SearchPage = () => {
  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  const [isLoading, setIsLoading] = useState(false);

  const products = useSelector((state) => state.products.products);

  const [noProductsFound, setNoProductsFound] = useState(false);

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const searchInput = searchParams.get('query');

  const sortBy = useSelector((state) => state.products.sortBy);

  useEffect(() => {
    setIsLoading(true);

    getSearchedProducts(searchInput, sortBy)
      .then((products) => {
        dispatch(setProducts(products));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      })
      .then(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchInput, sortBy]);

  useEffect(() => {
    const newNoProductsFound = products.length === 0 && !isLoading;

    setNoProductsFound(newNoProductsFound);
  }, [isLoading, products]);

  if (isLoading) return <Loading />;

  return (
    <Box
      sx={{
        padding: '40px',
        backgroundColor: '#f7f7f7',
        display: 'flex',
      }}
    >
      <Box sx={{ flex: 1, margin: { xs: '0', md: '20px 40px' } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: smallDevicesBreakpoint && 'column',
            alignItems: 'center',
            marginBottom: '30px',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              flex: 1,
              marginBottom: { xs: '20px', sm: '0px' },
              color: '#0b2a4a',
            }}
          >
            Resultados da pesquisa
          </Typography>

          {!noProductsFound && (
            <ProductSortBy smallDevicesBreakpoint={smallDevicesBreakpoint} />
          )}
        </Box>

        {!noProductsFound ? (
          <Grid
            container
            sx={{
              marginX: 0,
              gap: '18px',
            }}
          >
            {products.map((product) => {
              const noVariants = !product.variants.length;

              return !noVariants ? (
                <Grid item key={product._id} xs={5.6} sm={3.7} md={2.1}>
                  <ProductCard
                    product={{ ...product.variants[0], ...product }}
                  />
                </Grid>
              ) : null;
            })}
          </Grid>
        ) : (
          <Box sx={{ height: '25vh' }}>
            <Typography variant='h6'>{`Não foram encontrados resultados para ${
              searchInput || 'a sua pesquisa'
            }. Verifique a ortografia ou utilize uma pesquisa diferente.`}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchPage;
