import axios from 'axios';
import { getHeaders } from './login';
import { mapCategory } from './mapApiInterface';

const URL = `https://momelapi.bdca.pt/store`;

//  GET

function getPage(name) {
  return axios
    .get(`${URL}/pages/${name}`, getHeaders())
    .then(({ data: { page } }) => page);
}

function getCategories() {
  return axios.get(`${URL}/categories`, getHeaders()).then(({ data }) =>
    data.categories.map((category) => {
      return mapCategory(category);
    })
  );
}

/*
|--------------------------------
| GET CAMPAIGNS FROM API
|--------------------------------
*/
function getCampaigns() {
  return axios
    .get(`${URL}/campaigns`, getHeaders())
    .then(({ data: { campaigns } }) => campaigns);
}

function getCatalogs() {
  return axios
    .get(`${URL}/catalogs`, getHeaders())
    .then(({ data: { catalogs } }) => catalogs);
}

// POST

export { getCampaigns, getCatalogs, getCategories, getPage };
