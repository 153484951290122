import { LoadingButton } from '@mui/lab';
import { Box, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../api/login';
import {
  StyledFormInputLabel,
  StyledFormTextfield,
  StyledTextButton,
} from '../../styles';
import RegisterSuccess from './RegisterSuccess';

const UserRegisterForm = () => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    company: '',
    street: '',
    zipCode: '',
    city: '',
    taxNumber: '',
    phone: '',
    email: '',
    name: '',
    country: '',
    password: '',
    confirmPassword: '',
  });

  const [passwordConfirmationError, setPasswordConfirmationError] = useState({
    password: '',
    confirmPassword: '',
  });

  function validatePasswordConfirmation(e) {
    const { name, value } = e.target;

    setPasswordConfirmationError((prev) => {
      const passwordInputs = { ...prev, [name]: '' };

      switch (name) {
        case 'password':
          if (!value) {
            passwordInputs[name] = 'Por favor insira palavra-passe.';
          } else if (
            userData.confirmPassword &&
            value !== userData.confirmPassword
          ) {
            passwordInputs['confirmPassword'] =
              'Confirmação da palavra-passe não corresponde.';
          } else {
            passwordInputs['confirmPassword'] = userData.confirmPassword
              ? ''
              : passwordConfirmationError.confirmPassword;
          }
          break;

        case 'confirmPassword':
          if (!value) {
            passwordInputs[name] = 'Por favor confirme palavra-passe.';
          } else if (userData.password && value !== userData.password) {
            passwordInputs[name] =
              'Confirmação da palavra-passe não corresponde.';
          }
          break;

        default:
          break;
      }

      return passwordInputs;
    });
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({ ...values, [name]: value }));

    validatePasswordConfirmation(e);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    if (
      !userData.company ||
      !userData.street ||
      !userData.zipCode ||
      !userData.city ||
      !userData.taxNumber ||
      !userData.phone ||
      !userData.email ||
      !userData.name ||
      !userData.country ||
      !userData.password ||
      !userData.confirmPassword
    ) {
      setLoading(false);

      return;
    }

    registerUser(userData)
      .then(() => {
        setLoading(false);

        setShowConfirmation(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setLoading(false);

            const errorMessage = errors.reduce(
              (prevValue, { msg, value }) => `${prevValue} ${value}: ${msg}.
              `,
              ''
            );

            setError(true);

            setErrorMessage(errorMessage);
          }
        }
      );
  }

  if (showConfirmation) {
    return <RegisterSuccess />;
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '80vw', sm: '30vw' },
        marginY: { xs: '10px', sm: '20px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <StyledFormInputLabel gutterBottom sx={{ textAlign: 'center' }}>
        Registo de Novo Utilizador
      </StyledFormInputLabel>

      <StyledFormTextfield
        fullWidth
        label='Empresa'
        type='text'
        name='company'
        required
        value={userData.company}
        onChange={handleInputChange}
      />

      <StyledFormTextfield
        multiline
        fullWidth
        label='Morada'
        type='text'
        name='street'
        required
        value={userData.street}
        onChange={handleInputChange}
      />

      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          width: '100%',
        }}
      >
        <StyledFormTextfield
          fullWidth
          label='Código Postal'
          type='text'
          name='zipCode'
          required
          value={userData.zipCode}
          onChange={handleInputChange}
        />

        <StyledFormTextfield
          fullWidth
          label='Localidade'
          type='text'
          name='city'
          required
          value={userData.city}
          onChange={handleInputChange}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          width: '100%',
        }}
      >
        <StyledFormTextfield
          fullWidth
          label='País'
          type='text'
          name='country'
          required
          value={userData.country}
          onChange={handleInputChange}
        />

        <StyledFormTextfield
          fullWidth
          label='NIF'
          type='text'
          name='taxNumber'
          required
          value={userData.taxNumber}
          onChange={handleInputChange}
        />
      </Box>

      <StyledFormTextfield
        fullWidth
        label='Nome'
        type='text'
        name='name'
        required
        value={userData.name}
        onChange={handleInputChange}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '6px',
          width: '100%',
        }}
      >
        <StyledFormTextfield
          fullWidth
          label='Contacto'
          type='text'
          name='phone'
          required
          value={userData.phone}
          onChange={handleInputChange}
        />

        <StyledFormTextfield
          fullWidth
          label='Email'
          type='email'
          name='email'
          required
          value={userData.email}
          onChange={handleInputChange}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          marginBottom: { xs: '6px', sm: '20px' },
          gap: '6px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <StyledFormTextfield
            fullWidth
            label='Palavra-passe'
            type='password'
            name='password'
            required
            value={userData.password}
            onChange={handleInputChange}
            onBlur={validatePasswordConfirmation}
          />

          {passwordConfirmationError.password && (
            <Typography paddingLeft='8px' variant='caption' color='#ff5959'>
              {passwordConfirmationError.password}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <StyledFormTextfield
            fullWidth
            label='Confirmar Palavra-passe'
            type='password'
            name='confirmPassword'
            required
            value={userData.confirmPassword}
            onChange={handleInputChange}
            onBlur={validatePasswordConfirmation}
          />

          {passwordConfirmationError.confirmPassword && (
            <Typography paddingLeft='8px' variant='caption' color='#ff5959'>
              {passwordConfirmationError.confirmPassword}
            </Typography>
          )}
        </Box>
      </Box>

      {loading ? (
        <LoadingButton loading />
      ) : (
        <StyledTextButton variant='contained' type='submit'>
          Registar
        </StyledTextButton>
      )}

      <Snackbar
        autoHideDuration={10000}
        message={errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setError(false);
        }}
        ContentProps={{
          sx: { backgroundColor: '#ff5959', whiteSpace: 'pre-wrap' },
        }}
      />
    </Box>
  );
};

export default UserRegisterForm;
