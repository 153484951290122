import { Box, Tabs } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { StyledSidebarTab } from '../../styles';

const UserAccountNavBar = ({ smallAndMediumDevicesBreakpoint }) => {
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const seesUsers = isAdmin && {
    title: 'Lista de Utilizadores',
    url: 'utilizadores',
  };

  const ACCOUNT_LINKS = [
    { title: 'Os Meus Dados', url: 'info' },
    { title: 'Dados de Acesso', url: 'acesso' },
    { title: 'Livro de Endereços', url: 'moradas' },
    seesUsers,
    { title: 'Encomendas', url: 'encomendas' },
    { title: 'Conta-corrente', url: 'conta-corrente' },
  ].filter(Boolean);

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split('/');

  const currentLocation = splitLocation[splitLocation.length - 1];

  return (
    <Box
      sx={{
        width: smallAndMediumDevicesBreakpoint ? '100%' : '300px',
        height: '100%',
        marginBottom: '20px',
        backgroundColor: '#fff',
        overflowY: 'auto',
      }}
    >
      <Tabs
        orientation={
          smallAndMediumDevicesBreakpoint ? 'horizontal' : 'vertical'
        }
        variant={smallAndMediumDevicesBreakpoint ? 'scrollable' : 'standard'}
        value={currentLocation}
        scrollButtons
        allowScrollButtonsMobile
      >
        {ACCOUNT_LINKS.map(({ title, url }) => (
          <StyledSidebarTab
            key={url}
            value={url}
            label={title}
            component={Link}
            to={url}
            sx={{
              alignItems: smallAndMediumDevicesBreakpoint ? 'center' : 'start',
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default UserAccountNavBar;
