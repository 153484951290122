import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addShoppingList } from '../../api/user';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { StyledTextButton, StyledTextfield } from '../../styles';

const AddShoppingListModal = ({ isOpen, setIsOpen }) => {
  const [input, setInput] = useState('');

  const [hasConfirmation, setHasConfirmation] = useState(false);

  const [validationMessage, setValidationMessage] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function handleEntering() {
    setInput('');
  }

  function handleCreateShoppingList() {
    if (!input) return;

    const capitalizedTitle = input
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase().concat(word.slice(1)))
      .join(' ');

    addShoppingList(capitalizedTitle)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setHasConfirmation(true);

        setValidationMessage('Lista adicionada');
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setValidationMessage(msg);
          }
        }
      );

    setIsOpen(false);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {
            height: { xs: '50%', sm: '30%' },
            padding: '20px',
            backgroundColor: 'white',
          },
        }}
        TransitionProps={{ onEntering: handleEntering }}
      >
        <DialogTitle sx={{ color: '#0B2A4A', fontWeight: 700 }}>
          Criar nova Lista de Compras
        </DialogTitle>

        <DialogContent>
          <StyledTextfield
            label='Nome da nova lista de compras'
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <StyledTextButton
            variant='contained'
            color='secondaryColorButton'
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </StyledTextButton>

          <StyledTextButton
            variant='contained'
            onClick={handleCreateShoppingList}
          >
            Guardar Lista
          </StyledTextButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        autoHideDuration={4000}
        message={validationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={hasConfirmation}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setHasConfirmation(false);
        }}
        ContentProps={{
          sx: {
            backgroundColor:
              validationMessage === 'Lista adicionada' ? '#4FD290' : '#ff5959',
          },
        }}
      />
    </>
  );
};

export default AddShoppingListModal;
