import { Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { IconContext } from 'react-icons';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import Logo from '../Logo';

const SocialMedia = () => {
  return (
    <Grid item xs={10} sm={2.5} md={2}>
      <Logo />

      <Typography
        variant='caption'
        paragraph
        sx={{ marginTop: { xs: '10px', sm: '28px' } }}
      >
        Fique ligado!
      </Typography>

      <IconContext.Provider
        value={{ style: { marginRight: '10px', verticalAlign: 'middle' } }}
      >
        <Link
          href={`https://www.linkedin.com/company/momel/about/`}
          target='_blank'
          rel='noreferrer'
          underline='none'
        >
          <FiLinkedin />
        </Link>

        <Link
          href={`https://www.facebook.com/MomelSA`}
          target='_blank'
          rel='noreferrer'
          underline='none'
        >
          <FiFacebook />
        </Link>

        <Link
          href={`https://www.instagram.com/momel_s.a/`}
          target='_blank'
          rel='noreferrer'
          underline='none'
        >
          <FiInstagram />
        </Link>
      </IconContext.Provider>
    </Grid>
  );
};

export default SocialMedia;
