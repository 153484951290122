import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCart } from '../api/user';
import CartProgressIndicator from '../components/Cart/CartProgressIndicator';
import CartSummary from '../components/Cart/FirstStepSummary/CartSummary';
import OrderConfirmation from '../components/Cart/OrderConfirmation';
import OrderDetails from '../components/Cart/SecondStepOrderDetails/OrderDetails';
import { setCart, setCartId } from '../state/cartSlice';

const CartPage = () => {
  const [activeStep, setActiveStep] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);

  const cart = useSelector((state) => state.cart.cart);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    getCart()
      .then(({ cart_items: cart, _id: id }) => {
        dispatch(setCart(cart));

        dispatch(setCartId(id));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const total = cart.reduce(
      (
        previousValue,
        { package_quantity: unitsPerBox, price: pricePerUnit, quantity }
      ) =>
        previousValue +
        (pricePerUnit * quantity.units +
          pricePerUnit * quantity.boxes * unitsPerBox),
      0
    );

    setTotalPrice(total);
  }, [cart]);

  function handleActiveStep(step) {
    const element = document.querySelector('.app');

    element.scrollTo({ top: 0, behavior: 'smooth' });

    setActiveStep(step);

    navigate(undefined, { replace: true });
  }

  return cart.length === 0 ? (
    <Box
      sx={{
        padding: '40px',
        backgroundColor: '#f7f7f7',
        textAlign: 'center',
        height: '25vh',
      }}
    >
      <Typography variant='h6'>{`Não tem produtos no seu carrinho.`}</Typography>
    </Box>
  ) : (
    <Box
      sx={{
        padding: '40px',
        backgroundColor: '#f7f7f7',
      }}
    >
      <CartProgressIndicator activeStep={activeStep} />

      {activeStep === 0 && (
        <CartSummary totalPrice={totalPrice} setActiveStep={handleActiveStep} />
      )}

      {activeStep === 1 && (
        <OrderDetails
          totalPrice={totalPrice}
          setActiveStep={handleActiveStep}
        />
      )}

      {activeStep === 2 && <OrderConfirmation />}
    </Box>
  );
};

export default CartPage;
