import { Box, Typography } from '@mui/material';
import { StyledTextButton } from '../styles';

const NotFoundMessage = () => {
  return (
    <Box
      sx={{
        margin: '200px auto',
        textAlign: 'center',
      }}
    >
      <Typography variant='h5' gutterBottom>
        Página não encontrada.
      </Typography>

      <StyledTextButton variant='contained' href='/'>
        Continuar a comprar
      </StyledTextButton>
    </Box>
  );
};

export default NotFoundMessage;
