import { createSlice } from '@reduxjs/toolkit';

const productsSlice = createSlice({
  name: 'products',

  initialState: {
    filters: {},
    sortBy: 'date:desc',
    products: [],
    availableFilters: [],
  },

  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setProducts: (state, action) => {
      state.products = action.payload;
    },

    setAvailableFilters: (state, action) => {
      state.availableFilters = action.payload;
    },

    resetFilters: (state, { payload }) => {
      const { filterKeys } = payload;

      const emptyFilterObject = filterKeys.reduce(
        (previousValue, key) => ({ ...previousValue, [key]: [] }),
        {}
      );

      state.filters = emptyFilterObject;
    },

    addFilter: (state, action) => {
      const { id, type } = action.payload;

      const filterArray = [...state.filters[type]];

      state.filters[type] = [...filterArray, id];
    },

    removeFilter: (state, action) => {
      const { id, type } = action.payload;
      const filterArray = state.filters[type].filter((_id) => _id !== id);

      state.filters[type] = [...filterArray];
    },

    updateSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
  },
});

export const {
  setFilters,
  setProducts,
  setAvailableFilters,
  resetFilters,
  addFilter,
  removeFilter,
  updateSortBy,
} = productsSlice.actions;

export default productsSlice.reducer;
