import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPage } from '../../api';
import { formatTextToTitleCase } from '../../constants/utils';

const TermsOfServicePage = () => {
  const [terms, setTerms] = useState({});

  const navigate = useNavigate();

  const pageName = 'termos';

  const { title, description } = terms;

  useEffect(() => {
    getPage(pageName)
      .then(({ title, description }) => {
        setTerms({ title, description });
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: '40px',
        backgroundColor: '#f7f7f7',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        gutterBottom
        variant='h4'
        sx={{ fontWeight: 700, color: '#0b2a4a' }}
      >
        {formatTextToTitleCase(title, ['e', 'de'])}
      </Typography>

      <Typography
        gutterBottom
        sx={{ whiteSpace: 'pre-line', maxWidth: '70vw' }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default TermsOfServicePage;
