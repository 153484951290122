import titleCase from 'better-title-case';

function formatCurrency(number) {
  return number?.toLocaleString('pt-PT', {
    style: 'currency',
    currency: 'EUR',
  });
}

function formatTextToTitleCase(str, arr) {
  return titleCase(str, { excludedWords: arr });
}

function isValidUrl(categoryUrl, subcategoryUrls, categories) {
  const validCategory = categories.find(({ url }) => url === categoryUrl);

  if (!subcategoryUrls) {
    return Boolean(validCategory);
  }

  return (
    validCategory &&
    subcategoryUrls.every((subcategoryUrl) =>
      validCategory.subcategories.some(({ url }) => url === subcategoryUrl)
    )
  );
}

export { formatCurrency, formatTextToTitleCase, isValidUrl };
