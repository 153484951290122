import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Typography,
} from '@mui/material';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addFilter, removeFilter } from '../state/productsSlice';

const ProductFilter = ({ filter, type }) => {
	const { id, title, options } = filter;

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { subcategoriesSlug } = useParams();

	const filters = useSelector((state) => state.products.filters);

	const slugs = subcategoriesSlug.split('_');

	const isSubcategories = id === 'subcategories';

	const activeOptions = isSubcategories ? slugs : filters[id] || [];

	function handleFilterChange(checked, id) {
		const filterAction = checked ? addFilter : removeFilter;

		dispatch(filterAction({ id, type }));

		if (isSubcategories) {
			let newSlugs;

			if (checked) {
				newSlugs = [...slugs, id];
			} else {
				newSlugs = slugs.filter((slug) => slug !== id);
			}

			if (newSlugs.length) {
				navigate(`../${encodeURIComponent(newSlugs.join('_'))}`);
			}
		}
	}

	return (
		<Accordion
			defaultExpanded
			sx={{
				width: '250px',
				backgroundColor: '#f7f7f7',
				boxShadow: 'none',
			}}
		>
			<AccordionSummary
				expandIcon={<FiChevronDown size={24} color="#0b2a4a" />}
			>
				<Typography
					sx={{
						color: '#0b2a4a',
						fontWeight: '700',
					}}
				>
					{title}
				</Typography>
			</AccordionSummary>
			<Divider sx={{ borderColor: '#0b2a4a' }} />

			<AccordionDetails>
				<FormGroup>
					{options.map((option) => {
						return (
							<FormControlLabel
								key={option}
								label={option}
								control={
									<Checkbox
										checked={activeOptions.includes(option)}
										onChange={(e) =>
											handleFilterChange(
												e.target.checked,
												option
											)
										}
										sx={{ color: '#0b2a4a' }}
									/>
								}
								sx={{
									color: '#0b2a4a',
									display: 'inline-block',
								}}
							/>
						);
					})}
				</FormGroup>
			</AccordionDetails>
		</Accordion>
	);
};

export default ProductFilter;
