import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../../constants/utils';
import { StyledCartTableCell } from '../../../../styles';
import SummaryProductInfo from './SummaryProductInfo';
import SummaryQuantitySelector from './SummaryQuantitySelector';

const SummaryTable = () => {
  const cart = useSelector((state) => state.cart.cart);

  return (
    <TableContainer component={Paper} sx={{ marginY: '10px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledCartTableCell>Designação</StyledCartTableCell>

            <StyledCartTableCell>Quantidade</StyledCartTableCell>

            <StyledCartTableCell>Total de Unidades</StyledCartTableCell>

            <StyledCartTableCell>Preço Líquido</StyledCartTableCell>

            <StyledCartTableCell>Subtotal</StyledCartTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {cart.map((variant) => {
            const {
              variant_id: id,
              title,
              description,
              size,
              reference,
              stock,
              price: pricePerUnit,
              minimum_quantity: minQuantity,
              quantity,
              package_quantity: unitsPerBox,
              measure_unit: unit,
              images,
            } = variant;

            const { units, boxes } = quantity;

            return (
              <TableRow
                key={`${reference}-${id}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledCartTableCell>
                  {/* designação */}
                  <SummaryProductInfo
                    images={images}
                    title={title}
                    description={description}
                    reference={reference}
                    size={size}
                  />
                </StyledCartTableCell>

                <StyledCartTableCell>
                  {/* quantidade */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <SummaryQuantitySelector
                      quantity={quantity}
                      minQuantity={minQuantity}
                      unit={unit}
                      id={id}
                      reference={reference}
                      stock={stock}
                      unitsPerBox={unitsPerBox}
                    />
                  </Box>
                </StyledCartTableCell>

                <StyledCartTableCell>
                  {/* total de unidades */}
                  {units + boxes * unitsPerBox}
                </StyledCartTableCell>

                <StyledCartTableCell>
                  {/* preço líquido */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {Boolean(units) && (
                      <Typography>{`${formatCurrency(
                        pricePerUnit
                      )} ${unit}`}</Typography>
                    )}

                    {Boolean(boxes) && (
                      <Typography>{`${formatCurrency(
                        pricePerUnit * unitsPerBox
                      )} Embalagem`}</Typography>
                    )}
                  </Box>
                </StyledCartTableCell>

                <StyledCartTableCell>
                  {/* subtotal */}
                  {formatCurrency(
                    pricePerUnit * units + boxes * pricePerUnit * unitsPerBox
                  )}
                </StyledCartTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryTable;
