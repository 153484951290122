import { Box, MenuItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateSortBy } from '../state/productsSlice';
import { StyledTableSelect } from '../styles';

const ORDER_TYPES = [
  {
    id: 'name:asc',
    text: 'Nome A-Z',
  },
  {
    id: 'name:desc',
    text: 'Nome Z-A',
  },
  {
    id: 'price:asc',
    text: 'Preço Ascendente',
  },
  {
    id: 'price:desc',
    text: 'Preço Descendente',
  },
  {
    id: 'date:desc',
    text: 'Mais Recente',
  },
  {
    id: 'date:asc',
    text: 'Mais Antigo',
  },
];

const ProductSortBy = ({ smallDevicesBreakpoint }) => {
  const sortBy = useSelector((state) => state.products.sortBy);

  const dispatch = useDispatch();

  function handlesortByChange(e) {
    dispatch(updateSortBy(e.target.value));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: smallDevicesBreakpoint ? 'column' : 'row',
        marginLeft: 'auto',
      }}
    >
      <Typography
        sx={{
          fontWeight: '700',
        }}
      >
        ORDENAR POR:
      </Typography>

      <StyledTableSelect
        value={sortBy}
        onChange={handlesortByChange}
        size='small'
        autoWidth
      >
        {ORDER_TYPES.map((type) => (
          <MenuItem key={type.id} value={type.id}>
            {type.text}
          </MenuItem>
        ))}
      </StyledTableSelect>
    </Box>
  );
};

export default ProductSortBy;
