import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import login from '../assets/login2.svg';
import { ReactComponent as Logo2 } from '../assets/logo2.svg';

const LoginPage = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: '#f7f7f7',
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box
        component='img'
        src={login}
        alt=''
        sx={{
          height: { xs: '150px', sm: '100%' },
          width: { xs: '100%', sm: '50%' },
          objectFit: 'cover',
          marginBottom: { xs: '6px', sm: '0px' },
        }}
      />

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo2 />

        <Outlet />
      </Box>
    </Box>
  );
};

export default LoginPage;
