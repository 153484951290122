import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPage } from '../api';
import Brands from '../components/Homepage/Brands';
import Highlights from '../components/Homepage/Highlights';
import WelcomeImages from '../components/Homepage/WelcomeImages';
import {
  setBrands,
  setCampaigns,
  setMainBanner,
  setProducts,
} from '../state/homepageSlice';

const HomePage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const pageName = 'homepage';

  useEffect(() => {
    getPage(pageName)
      .then((page) => {
        const { mainBanner, campaigns, products, brands } = page;

        dispatch(setMainBanner(mainBanner));

        dispatch(setCampaigns(campaigns));

        dispatch(setProducts(products));

        dispatch(setBrands(brands));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: '#f7f7f7',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <WelcomeImages />

      <Highlights />

      <Brands />
    </Box>
  );
};

export default HomePage;
