import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo2 } from '../assets/logo2.svg';

const Logo = () => {
  const smallAndMediumDevicesBreakpoint = useMediaQuery('(max-width:768px)');

  return (
    <Grid item component={Link} to='/' marginRight='20px'>
      <Logo2 width={smallAndMediumDevicesBreakpoint ? '120px' : null} />
    </Grid>
  );
};

export default Logo;
