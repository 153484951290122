import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  inputLabelClasses,
  outlinedInputClasses,
  Select,
  Tab,
  TableCell,
  tableCellClasses,
  TextField,
  Typography,
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { FiPlus } from 'react-icons/fi';

export const theme = createTheme({
  typography: {
    fontFamily: 'Rubik',

    h1: {
      color: '#fff',
      fontSize: 36,
      fontWeight: 700,
    },

    h5: {
      fontWeight: 700,
    },
  },

  palette: {
    primary: {
      main: '#0b2a4a',
      text: '#fff',
    },
    secondaryColorButton: {
      main: '#fff',
      text: '#0b2a4a',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 16,
        },
      },
    },
  },
});

const StyledOrderSummaryBox = styled(Box)(() => ({
  margin: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledTextButton = styled(Button)(() => ({
  textTransform: 'none',
}));

const StyledTextfield = styled(TextField)(() => ({
  backgroundColor: '#fff',
  borderRadius: '4px',
  marginTop: '20px',
}));

const StyledFormTextfield = styled(TextField)(() => ({
  variant: 'outlined',
  backgroundColor: '#fff',
}));

const StyledSearchTextfield = styled(TextField)(() => ({
  [`& .${outlinedInputClasses.notchedOutline},
  .${outlinedInputClasses.input},
  .${inputLabelClasses.outlined},
  &:hover .${inputLabelClasses.outlined},
  .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    border: 0,
    color: '#fff',
  },
}));

const StyledSidebarTab = styled(Tab)(() => ({
  textTransform: 'none',
  fontWeight: 700,
  color: '#0b2a4a',
  marginRight: 'auto',
}));

const StyledCartTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
    color: '#0b2a4a',
    backgroundColor: '#ddd',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#0b2a4a',
    textAlign: 'center',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
    fontSize: '12px',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));

const StyledTableSelect = styled(Select)(() => ({
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 0,
  },
}));

const StyledFormInputLabel = styled(Typography)(() => ({
  color: '#0b2a4a',
  fontWeight: 700,
}));

const StyledOrderSummaryText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 700,
}));

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<FiPlus />} {...props} />
))(({ theme }) => ({
  backgroundColor: '#f7f7f7',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export {
  StyledTextButton,
  StyledTextfield,
  StyledSidebarTab,
  StyledSearchTextfield,
  StyledTableCell,
  StyledCartTableCell,
  StyledTableSelect,
  StyledFormInputLabel,
  StyledOrderSummaryBox,
  StyledOrderSummaryText,
  StyledFormTextfield,
  StyledAccordion,
  StyledAccordionSummary,
};
