import axios from 'axios';
import { Cookies } from 'react-cookie';

const URL = `https://momelapi.bdca.pt/store`;

const cookie = new Cookies();

function getHeaders() {
  return {
    headers: {
      authorization: cookie.get('userToken'),
    },
  };
}

// POST

function login(userData) {
  return axios.post(`${URL}/login`, userData).then(({ data }) => data);
}

function registerUser(userData) {
  return axios.post(`${URL}/register`, userData).then(({ data }) => data);
}

function recoverPassword(recoveryEmail) {
  return axios
    .post(`${URL}/forgot-password`, { email: recoveryEmail })
    .then(({ data }) => data);
}

function logout() {
  return axios.post(`${URL}/logout`, {}, getHeaders()).then(({ data }) => data);
}

function changeLogin(userData) {
  const {
    email,
    oldPassword,
    newPassword: password,
    confirmPassword: passwordConfirm,
  } = userData;

  return axios
    .post(
      `${URL}/user/update/login`,
      { email, oldPassword, password, passwordConfirm },
      getHeaders()
    )
    .then(({ data }) => data);
}

export {
  URL,
  getHeaders,
  login,
  registerUser,
  recoverPassword,
  logout,
  changeLogin,
};
