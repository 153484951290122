import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { formatCurrency } from '../../../constants/utils';
import { StyledTableCell } from '../../../styles';
import QuantitySelector from './QuantitySelector';

const ProductTable = ({ variants, selectForCart }) => {
  const isSingleVariant = variants.length === 1;

  return (
    <TableContainer component={Paper} sx={{ marginTop: 4, marginBottom: 2 }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Referência</StyledTableCell>

            <StyledTableCell>Código do Artigo</StyledTableCell>

            <StyledTableCell>Quantidade</StyledTableCell>

            <StyledTableCell>Unidades por embalagem</StyledTableCell>

            <StyledTableCell>Estado do Stock</StyledTableCell>

            <StyledTableCell>PVP*</StyledTableCell>

            <StyledTableCell>Desconto</StyledTableCell>

            <StyledTableCell>Preço Líquido*</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {variants.map((variant) => {
            const { size, reference, unitsPerBox, pvp, price, units, discountPercentage } = variant;
            const state = useState;

            const [isOutOfStock, setIsOutOfStock] = state(null);

            return (
              <TableRow
                key={reference}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <StyledTableCell>{size}</StyledTableCell>

                <StyledTableCell>{reference}</StyledTableCell>

                <StyledTableCell
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60px',
                  }}
                >
                  <QuantitySelector
                    isSingleVariant={isSingleVariant}
                    variant={variant}
                    selectForCart={selectForCart}
                    setIsOutOfStock={setIsOutOfStock}
                  />
                </StyledTableCell>

                <StyledTableCell>{unitsPerBox}</StyledTableCell>

                <StyledTableCell
                  sx={{ color: isOutOfStock ? '#ee7500' : '#83c231' }}
                >
                  {isOutOfStock ? 'Sob Encomenda' : 'Disponível'}
                </StyledTableCell>
                
                <StyledTableCell>
                  {`${formatCurrency(pvp)} / ${units}`}
                </StyledTableCell>
              
                <StyledTableCell>
                  {!!discountPercentage? `${discountPercentage}%` : '-'}
                </StyledTableCell>

                <StyledTableCell>
                  {`${formatCurrency(price)} / ${units}`}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
