import { Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FiBookmark, FiShoppingCart, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../api/login';
import { formatTextToTitleCase } from '../../constants/utils';
import { StyledTextButton } from '../../styles';

const AccountLinks = () => {
  const userName = useSelector((state) => state.user.personalInfo.name);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['userToken']);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const userRef = useRef(null);

  function handleLogout() {
    setIsMenuOpen(false);

    logout().then(() => {
      removeCookie('userToken', { path: '/' });
    });
  }

  return (
    <Grid item component='nav'>
      <StyledTextButton
        startIcon={<FiBookmark size={24} />}
        href='/listas-de-compras'
        sx={{
          minWidth: { xs: '44px' },
        }}
      >
        <Typography sx={{ display: { xs: 'none', md: 'flex' } }}>
          Listas de Compras
        </Typography>
      </StyledTextButton>

      <StyledTextButton
        startIcon={<FiShoppingCart size={24} />}
        component={Link}
        to='/carrinho'
        sx={{ minWidth: { xs: '44px' } }}
      >
        <Typography sx={{ display: { xs: 'none', md: 'flex' } }}>
          Carrinho
        </Typography>
      </StyledTextButton>

      <StyledTextButton
        startIcon={<FiUser size={24} />}
        ref={userRef}
        onClick={() => setIsMenuOpen(true)}
        sx={{ minWidth: { xs: '44px' } }}
      >
        <Typography sx={{ display: { xs: 'none', md: 'flex' } }}>
          {formatTextToTitleCase(userName.split(' ')[0])}
        </Typography>
      </StyledTextButton>

      <Menu
        anchorEl={userRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuItem
          component={Link}
          to='/conta/info'
          onClick={() => setIsMenuOpen(false)}
        >
          <Typography fontSize={14}>Conta</Typography>
        </MenuItem>

        <Divider />

        <MenuItem
          component={Link}
          to='/conta/encomendas'
          onClick={() => setIsMenuOpen(false)}
        >
          <Typography fontSize={14}>Encomendas</Typography>
        </MenuItem>

        <Divider />

        <MenuItem component={Link} to='/login' onClick={handleLogout}>
          <Typography fontSize={14}>Terminar sessão</Typography>
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default AccountLinks;
