import { Box, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../constants/utils';
import { StyledTextButton } from '../../styles';
import ShoppingListSelectorModal from './ShoppingListSelectorModal';

const ProductCheckout = ({
  addToCart,
  isDisabled,
  addToCartConfirmation,
  setAddToCartConfirmation,
  addToShoppingListConfirmation,
  setAddToShoppingListConfirmation,
  errorMessage,
  shoppingListErrorMessage,
  addToShoppingList,
  smallAndMediumDevicesBreakpoint,
}) => {
  const [addToShoppingListModal, setAddToShoppingListModal] = useState(false);

  const [totalQuantity, setTotalQuantity] = useState(0);

  const [totalPrice, seTotalPrice] = useState(0);

  const userSelections = useSelector((state) => state.product.userSelections);
  const productMeasureUnit = userSelections?.[0]?.measure_unit;

  const shoppingLists = useSelector(
    (state) => state.shoppingLists.shoppingLists
  );

  useEffect(() => {
    const { totalPrice, totalQuantity } = userSelections.reduce(
      (
        { totalPrice, totalQuantity },
        { type, quantity, price, pricePerBox, unitsPerBox }
      ) => {
        const isUnit = type === 'unit';

        const fixedPricePerBox = pricePerBox || price * unitsPerBox;

        const variantPrice = isUnit
          ? quantity * price
          : quantity * fixedPricePerBox;

        const variantQuantity = isUnit ? quantity : quantity * unitsPerBox;

        return {
          totalPrice: totalPrice + variantPrice,
          totalQuantity: totalQuantity + variantQuantity,
        };
      },
      { totalPrice: 0, totalQuantity: 0 }
    );

    setTotalQuantity(totalQuantity);

    seTotalPrice(totalPrice);
  }, [userSelections]);

  function handleAddToCart() {
    addToCart();
  }

  return (
    <Box
      sx={{
        marginY: '30px',
        display: 'flex',
        flexDirection: smallAndMediumDevicesBreakpoint && 'column',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
        }}
      >
        <Box sx={{ marginRight: '100px' }}>
          <Typography sx={{ fontSize: '12px', color: '#0b2a4a' }}>
            Quantidade total
          </Typography>

          <Typography
            sx={{ fontWeight: '700', fontSize: '12px', color: '#0b2a4a' }}
          >
            {`${totalQuantity} ${productMeasureUnit === 'KG' ? 'quilo(s)' : productMeasureUnit === 'MT' ? 'metros(s)' : 'unidade(s)'}`}
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ fontSize: '12px', color: '#0b2a4a' }}>
            Total
          </Typography>

          <Typography variant='h5' sx={{ color: '#0b2a4a' }}>
            {formatCurrency(totalPrice)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: '220px',
          marginY: '20px',
          marginLeft: smallAndMediumDevicesBreakpoint && 'auto',
        }}
      >
        <StyledTextButton
          variant='contained'
          fullWidth
          onClick={handleAddToCart}
          disabled={isDisabled}
          sx={{
            marginBottom: '10px',
            textAlign: 'center',
          }}
        >
          Adicionar ao Carrinho
        </StyledTextButton>

        <StyledTextButton
          disabled={!shoppingLists.length}
          variant='contained'
          color='secondaryColorButton'
          fullWidth
          onClick={() => setAddToShoppingListModal(true)}
        >
          {!shoppingLists.length
            ? 'Não tem Listas de Compras'
            : 'Adicionar à Lista de Compras'}
        </StyledTextButton>
      </Box>

      <ShoppingListSelectorModal
        isOpen={addToShoppingListModal}
        setIsOpen={setAddToShoppingListModal}
        addToShoppingList={addToShoppingList}
      />

      {addToCartConfirmation && (
        <Snackbar
          autoHideDuration={4000}
          message={
            addToCartConfirmation ? 'Adicionado ao carrinho' : errorMessage
          }
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={addToCartConfirmation}
          ContentProps={{
            sx: {
              backgroundColor: addToCartConfirmation ? '#4FD290' : '#ff5959',
            },
          }}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setAddToCartConfirmation(false);
          }}
        />
      )}

      {addToShoppingListConfirmation && (
        <Snackbar
          autoHideDuration={4000}
          message={
            addToShoppingListConfirmation
              ? 'Adicionado a lista de compras'
              : shoppingListErrorMessage
          }
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={addToShoppingListConfirmation}
          ContentProps={{
            sx: {
              backgroundColor: addToShoppingListConfirmation
                ? '#4FD290'
                : '#ff5959',
            },
          }}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setAddToShoppingListConfirmation(false);
          }}
        />
      )}
    </Box>
  );
};

export default ProductCheckout;
