import { Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StyledTextButton } from '../../../styles';

const CategoriesMobileNavBar = () => {
  const categoriesRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const categories = useSelector((state) => state.categories.categories);

  return (
		<>
			<StyledTextButton
				ref={categoriesRef}
				color="secondaryColorButton"
				onClick={() => setIsOpen(true)}
				sx={{
					borderRadius: '0px',
					fontWeight: '700',
					'&:hover': {
						backgroundColor: '#fff',
						color: '#0b2a4a',
					},
				}}
			>
				Produtos
			</StyledTextButton>

			<Menu
				anchorEl={categoriesRef.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				keepMounted
				open={isOpen}
				onClose={() => setIsOpen(false)}
			>
				{categories.map((category) => (
					<MenuItem
						key={`category-${category.id}`}
						component={Link}
						to={`categoria/${encodeURIComponent(category.url)}`}
						onClick={() => setIsOpen(false)}
						sx={{
							fontSize: 14,
						}}
					>
						{category.text}
					</MenuItem>
				))}
			</Menu>
		</>
  );
};

export default CategoriesMobileNavBar;
