import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';

const steps = ['Resumo', 'Informações', 'Confirmação'];

const CartProgressIndicator = ({ activeStep }) => {
  return (
    <Stepper alternativeLabel activeStep={activeStep} sx={{ marginY: '60px' }}>
      {steps.map((step) => (
        <Step key={step}>
          <StepLabel>{step}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CartProgressIndicator;
