import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getShoppingLists } from './api/user';
import './App.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { setShoppingLists } from './state/shoppingListsSlice';

function App() {
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const element = document.querySelector('.app');

    element.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  useEffect(() => {
    getShoppingLists()
      .then((lists) => {
        dispatch(setShoppingLists(lists));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className='app'>
      <Header />

      <Outlet />

      <Footer />
    </div>
  );
}

export default App;
