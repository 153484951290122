import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { StyledTextfield } from '../../../styles';

const CompanyInfo = () => {
  const companyInfo = useSelector((state) => state.user.companyInfo);

  const [smallDevicesBreakpoint] = useOutletContext();

  const { name, street, zipCode, city, country, taxNumber } = companyInfo;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: smallDevicesBreakpoint && 'column',
          gap: '10px',
        }}
      >
        <StyledTextfield
          label='Nome'
          defaultValue={name}
          disabled
          sx={{
            flex: 1,
          }}
        />

        <StyledTextfield
          label='Morada'
          defaultValue={street}
          disabled
          sx={{ flex: 1 }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: smallDevicesBreakpoint && 'column',
          gap: '10px',
        }}
      >
        <StyledTextfield
          label='Código Postal'
          defaultValue={zipCode}
          disabled
          sx={{ flex: 1 }}
        />

        <StyledTextfield
          label='Localidade'
          defaultValue={city}
          disabled
          sx={{ flex: 1 }}
        />

        <StyledTextfield
          label='País'
          defaultValue={country}
          disabled
          sx={{ flex: 1 }}
        />

        <StyledTextfield
          label='NIF'
          defaultValue={taxNumber}
          disabled
          sx={{ flex: 1 }}
        />
      </Box>
    </>
  );
};

export default CompanyInfo;
