import axios from 'axios';
import { getHeaders } from './login';

const URL = `https://momelapi.bdca.pt/store`;

//  GET

function getCart() {
  return axios
    .get(`${URL}/cart`, getHeaders())
    .then(({ data: { cart } }) => cart);
}

function getUser() {
  return axios
    .get(`${URL}/user`, getHeaders())
    .then(({ data: { user } }) => user);
}

function getUsers() {
  return axios
    .get(`${URL}/user/users`, getHeaders())
    .then(({ data: { users } }) => users);
}

function getPreviousOrders() {
  return axios
    .get(`${URL}/orders`, getHeaders())
    .then(({ data: { orders } }) => orders);
}

function getReceipts() {
  return axios
    .get(`${URL}/orders/account`, getHeaders())
    .then(({ data: { account } }) => account);
}

function getShoppingLists() {
  return axios
    .get(`${URL}/shopping-lists`, getHeaders())
    .then(({ data: { lists } }) => lists);
}

// POST

function addUser(newUserData) {
  return axios
    .post(`${URL}/user/create/user`, newUserData, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function updateUser(newUserData) {
  return axios
    .post(`${URL}/user/update/user`, newUserData, getHeaders())
    .then(({ data: user }) => user);
}

function addAddress(newDeliveryAddress) {
  return axios
    .post(`${URL}/user/create/address`, newDeliveryAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function updateAddress(updatedAddress) {
  return axios
    .post(`${URL}/user/update/address`, updatedAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function addShoppingList(name) {
  return axios
    .post(`${URL}/shopping-lists/create`, { name }, getHeaders())
    .then(({ data: { lists } }) => lists);
}

function deleteShoppingList(id) {
  return axios
    .post(`${URL}/shopping-lists/${id}/delete`, {}, getHeaders())
    .then(({ data: { lists } }) => lists);
}

function deleteProductFromShoppingList(listId, productId) {
  return axios
    .post(
      `${URL}/shopping-lists/${listId}/remove`,
      { product_id: productId },
      getHeaders()
    )
    .then(({ data: { lists } }) => lists);
}

export {
  getCart,
  getUser,
  getUsers,
  getPreviousOrders,
  getReceipts,
  getShoppingLists,
  addUser,
  updateUser,
  addAddress,
  updateAddress,
  addShoppingList,
  deleteShoppingList,
  deleteProductFromShoppingList,
};
