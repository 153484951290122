import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import AccessForm from './AccessForm';

const AccountAccess = () => {
  const personalInfo = useSelector((state) => state.user.personalInfo);

  if (!personalInfo) return null;

  return (
    <Box
      sx={{
        flex: 3,
        marginX: '40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography gutterBottom color='#0b2a4a' fontWeight={700}>
        Dados de Acesso à Conta
      </Typography>

      <Typography>
        Se desejar alterar a palavra-passe associada a esta conta, por favor
        preencha os seguintes campos.
      </Typography>

      <Typography>
        A palavra-passe atual é pedida por razões de segurança.
      </Typography>

      <AccessForm />
    </Box>
  );
};

export default AccountAccess;
