import { LoadingButton } from '@mui/lab';
import { Box, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { recoverPassword } from '../../api/login';
import {
  StyledFormInputLabel,
  StyledFormTextfield,
  StyledTextButton,
} from '../../styles';

const PasswordRecovery = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [confirmation, setConfirmation] = useState(false);

  const [recoveryEmail, setRecoveryEmail] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!recoveryEmail) return;

    recoverPassword(recoveryEmail)
      .then(() => {})
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status >= 400) {
            console.log('message', msg);
          }
        }
      )
      .then(() => {
        setIsLoading(false);

        setConfirmation(true);
      });
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: { xs: '80vw', sm: '30vw' },
          marginY: { xs: '20px', sm: '60px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <StyledFormInputLabel gutterBottom sx={{ textAlign: 'center' }}>
          Recuperar Palavra-Passe
        </StyledFormInputLabel>

        <Typography gutterBottom sx={{ color: '#0b2a4a', textAlign: 'center' }}>
          Por favor introduza o seu email para receber um link de reposição da
          sua palavra-passe.
        </Typography>

        <StyledFormTextfield
          fullWidth
          label='Introduza o seu email'
          type='email'
          required
          value={recoveryEmail}
          onChange={(e) => setRecoveryEmail(e.target.value)}
        />

        {isLoading ? (
          <LoadingButton loading />
        ) : (
          <StyledTextButton variant='contained' type='submit'>
            Recuperar
          </StyledTextButton>
        )}
      </Box>

      <Snackbar
        autoHideDuration={4000}
        message='Uma mensagem foi enviada para o email indicado.'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={confirmation}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setConfirmation(false);
        }}
        ContentProps={{ sx: { backgroundColor: '#4FD290' } }}
      />
    </>
  );
};

export default PasswordRecovery;
