import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProducts } from '../api/products';
import Loading from '../components/Loading';
import ProductCard from '../components/ProductCard';
import ProductFilter from '../components/ProductFilter';
import ProductFilterPopover from '../components/ProductFilterPopover';
import ProductSortBy from '../components/ProductSortBy';
import { isValidUrl } from '../constants/utils';
import {
  resetFilters,
  setAvailableFilters,
  setProducts,
} from '../state/productsSlice';

const ProductsPage = () => {
  const smallAndMediumDevicesBreakpoint = useMediaQuery('(max-width:768px)');

  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  const [isLoading, setIsLoading] = useState(false);

  const categories = useSelector((state) => state.categories.categories);

  const sortBy = useSelector((state) => state.products.sortBy);

  const filters = useSelector((state) => state.products.filters); // {} from user selection

  const availableFilters = useSelector(
    (state) => state.products.availableFilters
  ); // [] available filters on page from api response

  const products = useSelector((state) => state.products.products);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { categorySlug, subcategoriesSlug } = useParams();

  const subcategorySlugs = subcategoriesSlug.split('_');

  // reset filters on mount and on unmount
  useEffect(() => {
    dispatch(
      resetFilters({
        filterKeys: availableFilters?.map(({ id }) => id),
      })
    );

    return () => {
      dispatch(
        resetFilters({ filterKeys: availableFilters?.map(({ id }) => id) })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcategoriesSlug]);

  useEffect(() => {
    const subcategorySlugs = subcategoriesSlug.split('_');

    const newFilters = {
      ...filters,
      subcategories: subcategorySlugs,
    };

    setIsLoading(true);

    getProducts({ categorySlug, sortBy, filters: newFilters })
      .then(({ displayedFilters, displayedProducts }) => {
        dispatch(setProducts(displayedProducts));

        dispatch(setAvailableFilters(displayedFilters));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      })
      .then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySlug, dispatch, filters, sortBy, subcategoriesSlug]);

  if (!isValidUrl(categorySlug, subcategorySlugs, categories))
    navigate('/', { replace: true });

  const category = categories.find(({ url }) => url === categorySlug);

  if (!category) {
    return null;
  }

  return (
    <Box
      sx={{
        padding: '40px',
        backgroundColor: '#f7f7f7',
        display: 'flex',
      }}
    >
      {/* show filters on the side when above tablet width */}
      {!smallAndMediumDevicesBreakpoint && (
        <Box>
          {availableFilters.map((filter) => (
            <ProductFilter key={filter.id} type={filter.id} filter={filter} />
          ))}
        </Box>
      )}

      <Box sx={{ flex: 1, margin: { xs: '0', md: '20px 40px' } }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: { xs: '0', md: '30px' },
          }}
        >
          <Typography variant='h5' sx={{ flex: 1, color: '#0b2a4a' }}>
            {category.text}
          </Typography>

          {!smallAndMediumDevicesBreakpoint && <ProductSortBy />}
        </Box>

        {isLoading ? (
          <Loading />
        ) : !products.length ? (
          <Typography variant='h6'>{`Não foram encontrados produtos. Utilize uma pesquisa diferente.`}</Typography>
        ) : (
          <>
            {/* show filters on top when below tablet width */}
            {smallAndMediumDevicesBreakpoint && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'space-between',
                  marginY: '10px',
                }}
              >
                <ProductFilterPopover availableFilters={availableFilters} />

                <ProductSortBy
                  smallDevicesBreakpoint={smallDevicesBreakpoint}
                />
              </Box>
            )}

            <Grid
              container
              sx={{
                marginX: 0,
                gap: '18px',
              }}
            >
              {products.map((product) => {
                const {
                  variants
                } = product;

                if (!variants.length) return null;

                return (
                  <Grid
                    key={variants?.[0]?.reference}
                    item
                    xs={5.6}
                    sm={3.7}
                    md={2.1}
                  >
                    <ProductCard product={{ ...variants, ...product }} />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProductsPage;
