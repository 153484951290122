import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPreviousOrders, getReceipts } from '../../../api/user';
import { setPreviousOrders, setReceipts } from '../../../state/userSlice';
import Loading from '../../Loading';
import ExportToExcelButton from './ExportToExcelButton';
import OrdersTable from './OrdersTable';
import PurchasesFilters from './PurchasesFilters';
import ReceiptsTable from './ReceiptsTable';

const AccountPurchases = ({ props: { mode } }) => {
  // entities can be orders or receipts
  const isOrders = mode === 'orders';

  const [entities, setEntities] = useState({ orders: [], receipts: [] });

  const orders = useSelector((state) => state.user.orders);

  const receipts = useSelector((state) => state.user.receipts);

  const [selectedStatus, setSelectedStatus] = useState('all');

  const [selectedYear, setSelectedYear] = useState('all');

  const [search, setSearch] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    getPreviousOrders()
      .then((orders) => {
        dispatch(setPreviousOrders(orders.reverse()));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    getReceipts()
      .then((receipts) => {
        dispatch(setReceipts(receipts.reverse()));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const entities = { orders, receipts };

    setEntities({ ...entities });

    setSelectedStatus('all');

    setSelectedYear('all');
  }, [mode, orders, receipts]);

  const detailsMode = isOrders ? entities.orders : entities.receipts;

  const shownEntities = [...detailsMode];

  const filteredByStatus = shownEntities.filter(({ status }) => {
    if (selectedStatus === 'all') return true;

    return selectedStatus === status;
  });

  const filteredByYearAndStatus = filteredByStatus.filter(({ date }) => {
    if (selectedYear === 'all') return true;

    return selectedYear === new Date(date).getFullYear();
  });

  const filteredByEverything = filteredByYearAndStatus.filter(({ reference }) =>
    reference.includes(search)
  );

  return (
    <Box
      sx={{
        flex: 3,
        marginX: '40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography gutterBottom color='#0b2a4a' fontWeight={700}>
        {isOrders ? 'Encomendas' : 'Conta-corrente'}
      </Typography>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <PurchasesFilters
            type={detailsMode}
            selectedStatus={selectedStatus}
            selectedYear={selectedYear}
            setSelectedStatus={setSelectedStatus}
            setSelectedYear={setSelectedYear}
            search={search}
            setSearch={setSearch}
          />

          {filteredByEverything.length !== 0 && (
            <Box>
              {isOrders && <OrdersTable shownOrders={filteredByEverything} />}

              {!isOrders && <ReceiptsTable receipts={filteredByEverything} />}

              <ExportToExcelButton isOrders={isOrders} />
            </Box>
          )}

          {filteredByEverything.length === 0 && (
            <Typography sx={{ marginY: '20px', textAlign: 'center' }}>
              Não há registos para mostrar com estes parâmetros.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default AccountPurchases;
