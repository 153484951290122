import { AppBar, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import Logo from '../Logo';
import AccountLinks from './AccountLinks';
import CategoriesNavBar from './CategoriesNavBar/CategoriesNavBar';
import SearchBar from './SearchBar';

const Header = () => {
  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  return (
    <AppBar position='sticky'>
      <Grid
        container
        sx={{
          backgroundColor: '#fff',
          padding: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo />

        {smallDevicesBreakpoint ? (
          <>
            <AccountLinks />

            <SearchBar />
          </>
        ) : (
          <>
            <SearchBar />

            <AccountLinks />
          </>
        )}
      </Grid>

      <CategoriesNavBar />
    </AppBar>
  );
};

export default Header;
