import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { StyledFormInputLabel, StyledFormTextfield } from '../../../../styles';
import DeliveryAtAddress from './DeliveryAtAddress';
import DeliveryAtStore from './DeliveryAtStore';

const DetailsForm = ({
  shipping,
  onShippingChange,
  address,
  onAddressChange,
  onStoreChange,
  store,
  handleInputChange,
  orderDetails,
  deliveryOptions,
}) => {
  const isDeliveryAtAddress = shipping === 'deliveryAtAddress';

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <StyledFormInputLabel>Pagamento</StyledFormInputLabel>

      <StyledFormTextfield
        label='Método De Pagamento'
        defaultValue='Transferência Bancária'
        InputProps={{
          readOnly: true,
        }}
      />

      <StyledFormTextfield
        label='Pagamento Em'
        defaultValue='7 Dias'
        InputProps={{
          readOnly: true,
        }}
      />

      <FormControl>
        <RadioGroup
          value={shipping}
          onChange={onShippingChange}
          name='shipping-options'
        >
          <FormControlLabel
            value='deliveryAtAddress'
            disabled={!deliveryOptions.includes('deliveryAtAddress')}
            control={<Radio />}
            label={<StyledFormInputLabel>Entrega</StyledFormInputLabel>}
          />

          <DeliveryAtAddress
            disabled={!isDeliveryAtAddress}
            address={address}
            onAddressChange={onAddressChange}
            orderDetails={orderDetails}
            handleInputChange={handleInputChange}
          />

          <FormControlLabel
            value='deliveryAtStore'
            disabled={!deliveryOptions.includes('deliveryAtStore')}
            control={<Radio />}
            label={
              <StyledFormInputLabel>Levantamento em Loja</StyledFormInputLabel>
            }
          />

          <DeliveryAtStore
            disabled={
              !deliveryOptions.includes('deliveryAtStore') ||
              isDeliveryAtAddress
            }
            store={store}
            onStoreChange={onStoreChange}
          />
        </RadioGroup>
      </FormControl>

      <Typography marginTop='20px'>
        Poderá escolher apenas um método de envio.
      </Typography>
    </Box>
  );
};

export default DetailsForm;
