import { Box, Popover } from '@mui/material';
import React, { useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { StyledTextButton } from '../styles';
import ProductFilter from './ProductFilter';

const ProductFilterPopover = ({ availableFilters }) => {
  const filterRef = useRef(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Box>
      <StyledTextButton
        variant='contained'
        color='secondaryColorButton'
        ref={filterRef}
        onClick={() => setIsPopoverOpen(true)}
        disableElevation
        endIcon={<FiPlus size={12} />}
      >
        Selecionar Filtros
      </StyledTextButton>

      <Popover
        open={isPopoverOpen}
        anchorEl={filterRef.current}
        onClose={() => setIsPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ overflowY: 'scroll' }}
        PaperProps={{
          style: { maxHeight: '60%' },
        }}
      >
        <Box sx={{ backgroundColor: '#f7f7f7' }}>
          {availableFilters.map((filter) => (
            <ProductFilter key={filter.id} type={filter.id} filter={filter} />
          ))}

          <StyledTextButton
            variant='contained'
            onClick={() => setIsPopoverOpen(false)}
            sx={{ margin: '10px' }}
          >
            Aplicar
          </StyledTextButton>
        </Box>
      </Popover>
    </Box>
  );
};

export default ProductFilterPopover;
