import { Box, Typography } from '@mui/material';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { StyledTextButton } from '../../styles';

const RegisterSuccess = () => {
  return (
    <Box
      sx={{
        width: { xs: '80vw', sm: '30vw' },
        marginY: { xs: '20px', sm: '60px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '20px',
      }}
    >
      <IconContext.Provider
        value={{ style: { color: '#4FD290', margin: '10px' } }}
      >
        <BsCheckCircle size={64} />
      </IconContext.Provider>

      <Typography gutterBottom>
        O seu registo foi efetuado com sucesso. Receberá um email com a
        aprovação da conta.
      </Typography>

      <StyledTextButton
        variant='contained'
        href='https://momel-b2c.myshopify.com'
      >
        Ir para momel.pt
      </StyledTextButton>
    </Box>
  );
};

export default RegisterSuccess;
