import { Box, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { validateVoucher } from '../../../api/checkout';
import {
  setValidVoucher,
  setVoucherCode,
  setVoucherDiscount,
} from '../../../state/cartSlice';
import { StyledTextButton } from '../../../styles';

const PromoCode = () => {
  const [voucherInput, setVoucherInput] = useState('');

  const [hasConfirmation, setHasConfirmation] = useState(false);

  const [validationMessage, setValidationMessage] = useState('');

  const voucherCode = useSelector((state) => state.cart.voucherCode);

  const validVoucher = useSelector((state) => state.cart.validVoucher);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isInvalidCode = voucherInput.trim() === '';

  function onSubmit() {
    validateVoucher(voucherInput.trim())
      .then(({ value, code }) => {
        dispatch(setVoucherDiscount(value));

        dispatch(setVoucherCode(code));

        dispatch(setValidVoucher(true));

        setValidationMessage('Código aplicado!');
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setValidationMessage(msg);

            dispatch(setValidVoucher(false));
          }
        }
      )
      .then(() => {
        setHasConfirmation(true);

        setVoucherInput('');
      });
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'start',
        marginBottom: { xs: '20px', sm: 0 },
      }}
    >
      <TextField
        label='Introduzir código promocional'
        value={voucherInput}
        onChange={(e) => setVoucherInput(e.target.value)}
        size='small'
        variant='outlined'
        helperText={
          validVoucher && `Código promocional "${voucherCode}" aplicado.`
        }
        FormHelperTextProps={{
          style: { backgroundColor: '#f7f7f7', margin: 0, padding: '6px' },
        }}
        sx={{
          width: '250px',
          backgroundColor: '#fff',
        }}
      />

      <StyledTextButton disabled={isInvalidCode} onClick={onSubmit}>
        Aplicar
      </StyledTextButton>

      <Snackbar
        autoHideDuration={4000}
        message={validationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={hasConfirmation}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setHasConfirmation(false);
        }}
        ContentProps={{
          sx: {
            backgroundColor:
              validationMessage === 'Código aplicado!' ? '#4FD290' : '#ff5959',
          },
        }}
      />
    </Box>
  );
};

export default PromoCode;
