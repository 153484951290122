import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { IconContext } from 'react-icons';
import { FiChevronRight } from 'react-icons/fi';

const ContactCard = ({ store }) => {
  const { title, address, phone, mobile, email } = store;

  const addressAsString = Object.values(address).join(' ');

  const { street, zipCode, city, country } = address;

  return (
    <Box sx={{ width: '280px', marginY: '30px' }}>
      <Typography fontSize='24px' fontWeight={700} color='#0b2a4a' gutterBottom>
        {title}
      </Typography>

      <Typography gutterBottom>
        {street} <br /> {zipCode} {city} <br />
        {country}
      </Typography>

      {phone && <Typography>{`Telefone: ${phone}`}</Typography>}

      {mobile && <Typography>{`Telemóvel: ${mobile}`}</Typography>}

      {email && <Typography gutterBottom>{`Email: ${email}`}</Typography>}

      <Typography sx={{ fontWeight: 700 }}>
        <Link
          href={`https://maps.google.com/?q=${addressAsString}`}
          target='_blank'
          rel='noreferrer'
          underline='none'
        >
          Obter direções
        </Link>

        <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
          <FiChevronRight />
        </IconContext.Provider>
      </Typography>
    </Box>
  );
};

export default ContactCard;
