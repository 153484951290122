import { Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CustomerSupport = () => {
  return (
    <Grid item xs={10} sm={2.5} md={2}>
      <Typography variant='h5' gutterBottom>
        Apoio ao Cliente
      </Typography>

      <Typography
        gutterBottom
        component={RouterLink}
        to='/termos'
        sx={{
          display: 'block',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        Termos e Condições
      </Typography>

      <Typography
        gutterBottom
        component={RouterLink}
        to='/privacidade'
        sx={{
          display: 'block',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        Política de Privacidade
      </Typography>

      <Typography gutterBottom>
        <Link
          href='https://www.livroreclamacoes.pt/Inicio/'
          target='_blank'
          rel='noreferrer'
          underline='none'
        >
          Livro de Reclamações
        </Link>
      </Typography>
    </Grid>
  );
};

export default CustomerSupport;
