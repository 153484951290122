import { LoadingButton } from '@mui/lab';
import { Box, Snackbar, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkDeliveryOptions, confirmOrder } from '../../../api/checkout';
import { getCart } from '../../../api/user';
import { setCart, setCartTotal, setValidVoucher, setVoucherCode, setVoucherDiscount } from '../../../state/cartSlice';
import { StyledTextButton } from '../../../styles';
import DetailsForm from './DetailsForm/DetailsForm';
import OrderSummaryCard from './OrderSummaryCard';

const DELIVERY_AT_STORE = 'deliveryAtStore';

const OrderDetails = ({ setActiveStep, totalPrice }) => {
  const smallAndMediumDevicesBreakpoint = useMediaQuery('(max-width:768px)');

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const [shipping, setShipping] = useState(DELIVERY_AT_STORE);

  const [deliveryCost, setDeliveryCost] = useState(0);

  const [addressDeliveryCost, setAddressDeliveryCost] = useState(0);

  const [deliveryOptions, setDeliveryOptions] = useState([]);

  const [address, setAddress] = useState('');

  const [store, setStore] = useState('');

  const cart = useSelector((state) => state.cart.cart);

  const cartId = useSelector((state) => state.cart.id);

  const voucherDiscount = useSelector((state) => state.cart.voucherDiscount);

  const subtotal = totalPrice;

  const tax = subtotal * 0.23;

  const total = subtotal + tax + deliveryCost - voucherDiscount;

  const [orderDetails, setOrderDetails] = useState({
    paymentType: 'Transferência Bancária',
    paymentTime: '7 Dias',
    shipping,
    deliveryAtAddress: address,
    deliveryAtStore: store,
    notes: '',
    total: total,
    total_discount: voucherDiscount,
    cart,
    cart_id: cartId,
  });

  const disabled = !store && !address;

  useEffect(() => {
    checkDeliveryOptions()
      .then(({ deliveryCost, deliveryMethods }) => {
        setAddressDeliveryCost(deliveryCost);
        setDeliveryOptions(deliveryMethods);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCart()
      .then(({ cart_items: cart, total: totalPrice, total_discount: voucherDiscount }) => {
        dispatch(setCart(cart));

        dispatch(setCartTotal(totalPrice));

        dispatch(setVoucherDiscount(voucherDiscount));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //  control shipping cost based on shipping method
  useEffect(() => {
    if (shipping === DELIVERY_AT_STORE) setDeliveryCost(0);
    else setDeliveryCost(addressDeliveryCost);
  }, [shipping, addressDeliveryCost]);

  function onShippingChange(e) {
    setShipping(e.target.value);

    setOrderDetails((values) => ({ ...values, shipping: e.target.value }));
  }

  function onAddressChange(e) {
    setAddress(e.target.value);

    setOrderDetails((values) => ({
      ...values,
      deliveryAtAddress: e.target.value,
    }));
  }

  function onStoreChange(e) {
    setStore(e.target.value);

    setOrderDetails((values) => ({
      ...values,
      deliveryAtStore: e.target.value,
    }));
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    setOrderDetails((values) => ({ ...values, [name]: value }));
  }

  function handleConfirmOrder() {
    setOrderDetails((values) => ({
      ...values,
      shipping,
      deliveryAtAddress: shipping === DELIVERY_AT_STORE ? '' : address,
      deliveryAtStore: shipping === DELIVERY_AT_STORE ? store : '',
      total: subtotal + tax + deliveryCost - voucherDiscount,
      total_discount: voucherDiscount,
      cart,
    }));

    setIsLoading(true);

    confirmOrder(orderDetails)
      .then(() => {
        setIsLoading(false);

        setActiveStep(2);

        dispatch(setValidVoucher(false));

        dispatch(setVoucherCode(''));
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setIsLoading(false);

            setError(true);

            setErrorMessage(msg);
          }
        }
      );
  }

  return (
    <Box
      sx={{
        backgroundColor: '#f7f7f7',
      }}
    >
      {/* order of components in width below tablet */}
      {smallAndMediumDevicesBreakpoint ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <OrderSummaryCard cart={cart} tax={tax} deliveryCost={deliveryCost} voucherDiscount={voucherDiscount} />

          <DetailsForm
            shipping={shipping}
            onShippingChange={onShippingChange}
            address={address}
            onAddressChange={onAddressChange}
            onStoreChange={onStoreChange}
            store={store}
            handleInputChange={handleInputChange}
            orderDetails={orderDetails}
            deliveryOptions={deliveryOptions}
          />
        </Box>
      ) : (
        //  order of components in width above tablet
        <Box sx={{ display: 'flex' }}>
          <DetailsForm
            shipping={shipping}
            onShippingChange={onShippingChange}
            address={address}
            onAddressChange={onAddressChange}
            onStoreChange={onStoreChange}
            store={store}
            handleInputChange={handleInputChange}
            orderDetails={orderDetails}
            deliveryOptions={deliveryOptions}
          />

          <OrderSummaryCard cart={cart} tax={tax} deliveryCost={deliveryCost} voucherDiscount={voucherDiscount} />
        </Box>
      )}

      <Box
        sx={{
          marginY: '20px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <StyledTextButton
          variant='contained'
          color='secondaryColorButton'
          onClick={() => setActiveStep(0)}
          sx={{ marginLeft: 'auto' }}
        >
          Voltar atrás
        </StyledTextButton>

        {isLoading ? (
          <LoadingButton loading />
        ) : (
          <StyledTextButton disabled={disabled} variant='contained' onClick={handleConfirmOrder} sx={{ marginLeft: '10px' }}>
            Finalizar
          </StyledTextButton>
        )}

        <Snackbar
          autoHideDuration={6000}
          message={errorMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={error}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setError(false);
          }}
          ContentProps={{ sx: { backgroundColor: '#ff5959' } }}
        />
      </Box>
    </Box>
  );
};

export default OrderDetails;
