import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',

  initialState: {
    personalInfo: { name: 'User User' },
    companyInfo: {},
    isAdmin: false,
    addresses: [],
    orders: [],
    receipts: [],
  },

  reducers: {
    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },

    setAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },

    setCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },

    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },

    setPreviousOrders: (state, action) => {
      state.orders = action.payload;
    },

    setReceipts: (state, action) => {
      state.receipts = action.payload;
    },
  },
});

export const {
  setPersonalInfo,
  setAdmin,
  setCompanyInfo,
  setAddresses,
  setPreviousOrders,
  setReceipts,
} = userSlice.actions;

export default userSlice.reducer;
