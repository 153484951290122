import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',

  initialState: {
    id: '',
    cart: [],
    total: 0,
    voucherDiscount: 0,
    voucherCode: '',
    validVoucher: false,
    shipping: '',
    deliveryAtAddress: '',
    deliveryAtStore: '',
    shippingNotes: '',
  },

  reducers: {
    setCartId: (state, action) => {
      state.id = action.payload;
    },

    // real use case: reset cart after order completion
    setCart: (state, action) => {
      state.cart = action.payload;
    },

    setCartTotal: (state, action) => {
      state.total = action.payload;
    },

    setVoucherDiscount: (state, action) => {
      state.voucherDiscount = action.payload;
    },

    setVoucherCode: (state, action) => {
      state.voucherCode = action.payload;
    },

    setValidVoucher: (state, action) => {
      state.validVoucher = action.payload;
    },

    updateCart: (state, action) => {
      const oldCart = state.cart;
      const products = action.payload;

      const existingProducts = oldCart.filter(({ reference }) =>
        products.some(
          ({ reference: newReference }) => reference === newReference
        )
      );

      const productsToAdd = products.filter(
        ({ reference }) =>
          !existingProducts.some(
            ({ reference: newReference }) => reference === newReference
          )
      );

      const updatedCart = oldCart.map((product) => {
        const foundProduct = products.find(
          ({ reference }) => reference === product.reference
        );
        if (foundProduct) {
          const newProduct = { ...foundProduct };
          const { quantity } = newProduct;

          product.quantity.boxes += quantity.boxes || 0;
          product.quantity.units += quantity.units || 0;

          return product;
        } else {
          return product;
        }
      });

      state.cart = [...updatedCart, ...productsToAdd];
    },

    // Removes variant from cart
    removeFromCart: (state, action) => {
      const { reference, unitType } = action.payload;

      const cart = state.cart;

      const foundProduct = cart.find(
        ({ reference: newReference }) => newReference === reference
      );

      const { quantity } = foundProduct;

      const newQuantity = { ...quantity, [unitType]: 0 };

      const newProduct = { ...foundProduct, quantity: newQuantity };

      const updatedCart = cart.map((product) =>
        product.reference === reference ? newProduct : product
      );

      const newCart = updatedCart.filter(
        ({ quantity: { units, boxes } }) => !(units === 0 && boxes === 0)
      );

      state.cart = [...newCart];
    },

    // Removes quantity, or product, from cart
    removeQuantityFromCartItem: (state, action) => {
      const oldCart = state.cart;
      const products = action.payload;

      const updatedProducts = oldCart.map((product) => {
        const foundProduct = products.find(
          ({ reference }) => reference === product.reference
        );
        const newProduct = { ...product };
        const newQuantity = foundProduct?.quantity || { units: 0, boxes: 0 };

        newProduct.quantity.boxes -= newQuantity.boxes || 0;
        newProduct.quantity.units -= newQuantity.units || 0;

        return newProduct;
      });

      const updatedCart = updatedProducts.filter(
        ({ quantity: { units, boxes } }) => !(units === 0 && boxes === 0)
      );

      state.cart = [...updatedCart];
    },
  },
});

export const {
  setCartId,
  setCart,
  setCartTotal,
  setVoucherDiscount,
  setVoucherCode,
  setValidVoucher,
  updateCart,
  removeFromCart,
  removeQuantityFromCartItem,
} = cartSlice.actions;

export default cartSlice.reducer;
