import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledTextButton } from '../../styles';

const ShoppingListSelectorModal = ({
  isOpen,
  setIsOpen,
  addToShoppingList,
}) => {
  const shoppingLists = useSelector(
    (state) => state.shoppingLists.shoppingLists
  );

  const [selectShoppingList, setSelectShoppingList] = useState(
    shoppingLists[0]?._id
  );

  function handleAddToShoppingList() {
    addToShoppingList(selectShoppingList);

    setIsOpen(false);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          height: { xs: '50%', sm: '30%' },
          padding: '20px',
          backgroundColor: 'white',
        },
      }}
    >
      <DialogTitle sx={{ color: '#0B2A4A', fontWeight: 700 }}>
        Selecione a Lista de Compras
      </DialogTitle>

      <DialogContent>
        <Select
          fullWidth
          size='small'
          value={selectShoppingList}
          onChange={(e) => setSelectShoppingList(e.target.value)}
          sx={{ backgroundColor: '#fff' }}
        >
          {shoppingLists.map(({ name, _id }) => (
            <MenuItem key={_id} value={_id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>

      <DialogActions>
        <StyledTextButton
          variant='contained'
          color='secondaryColorButton'
          onClick={() => setIsOpen(false)}
        >
          Cancelar
        </StyledTextButton>

        <StyledTextButton variant='contained' onClick={handleAddToShoppingList}>
          Adicionar
        </StyledTextButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShoppingListSelectorModal;
