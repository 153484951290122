import { Box, Container, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCatalogs } from '../api';
import Loading from '../components/Loading';
import { StyledTextButton } from '../styles';

const Catalogs = () => {
  const [catalogs, setCatalogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCatalogs()
      .then((catalogs) => {
        console.log(33, catalogs);
        setLoading(false);
        setCatalogs(catalogs);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container maxWidth='lg'>
      <Grid
        container
        spacing={{ xs: 2 }}
        sx={{ paddingTop: 6, marginTop: { xs: 0 } }}
      >
        <Grid
          item
          xs={12}
          paddingTop='0 !important'
        >
          <Typography
            variant='h1'
            fontWeight={700}
            color='#0b2a4a'
            textAlign='center'
          >
            Catálogos
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={{ xs: 2 }}
        sx={{ paddingTop: 4, marginTop: { xs: 0 } }}
      >
        {catalogs.length !== 0 ? (
          catalogs?.map((catalog) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              component={Link}
              href={catalog?.url}
              target='_blank'
              title='Ver catálogo'
              key={`catalog-${catalog?.id}`}
              sx={{
                textDecoration: 'none',
                paddingTop: '0!important',
                marginBottom: { xs: 4, sm: 6 },
                '&:hover': {
                  '.MuiButtonBase-root': {
                    backgroundColor: 'rgba(11, 42, 74, 0.04)',
                  },
                },
              }}
            >
              {catalog?.image && (
                <Box
                  component='img'
                  src={catalog?.image}
                  sx={{
                    aspectRatio: '2/3',
                    maxWidth: '100%',
                  }}
                  alt={catalog?.name}
                />
              )}
              {catalog?.name && (
                <Typography
                  variant='h5'
                  sx={{ color: '#0b2a4a', marginTop: 1.5 }}
                >
                  {catalog?.name}
                </Typography>
              )}
              <StyledTextButton
                size='small'
                sx={{
                  marginTop: 2,
                  color: '#0b2a4a',
                  border: '2px solid #0b2a4a',
                  paddingX: '20px',
                }}
              >
                Ver catálogo
              </StyledTextButton>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography sx={{ color: '#0b2a4a', textAlign: 'center' }}>
              Não há catálogo disponíveis.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Catalogs;
