import { Grid, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '../../../api';
import { setCategories } from '../../../state/categoriesSlice';
import CampaignsButton from './CampaignsButton';
import CategoriesMobileNavBar from './CategoriesMobileNavBar';
import SubcategoriesNavBar from './SubcategoriesNavBar';

const CategoriesNavBar = () => {
  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  const categories = useSelector((state) => state.categories.categories);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    getCategories()
      .then((newCategories) => {
        dispatch(setCategories(newCategories));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Grid component='nav' container sx={{ justifyContent: 'center' }}>
      {smallDevicesBreakpoint ? (
        <CategoriesMobileNavBar />
      ) : (
        categories.map((category) => (
          <SubcategoriesNavBar
            key={`category-${category.url}`}
            category={category}
          />
        ))
      )}

      <CampaignsButton />
    </Grid>
  );
};

export default CategoriesNavBar;
