import { Box, Container, Grid, Typography, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCampaigns } from '../api';
import Loading from '../components/Loading';
import { StyledTextButton } from '../styles';

const CampaignsPage = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCampaigns()
      .then((campaignsArray) => {
        setLoading(false);
        setCampaigns(campaignsArray);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container maxWidth='lg'>
      <Grid container spacing={{ xs: 2 }} sx={{ paddingTop: 6, marginTop: { xs: 0 } }}>
        {campaigns.length !== 0 ? (
          campaigns?.map((campaign) => (
            <Grid
              item
              xs={12}
              sm={6}
              component={Link}
              href={campaign?.url}
              title={campaign?.name}
              key={`campaign-${campaign?.id}`}
              sx={{
                textDecoration: 'none',
                paddingTop: '0!important',
                marginBottom: { xs: 4, sm: 6 },
                '&:hover': {
                  '.MuiButtonBase-root': {
                    backgroundColor: 'rgba(11, 42, 74, 0.04)',
                  },
                },
              }}
            >
              {campaign?.image && (
                <Box
                  component='img'
                  src={campaign?.image}
                  sx={{
                    aspectRatio: '2/1',
                    maxWidth: '100%',
                  }}
                  alt={campaign?.name}
                />
              )}
              {campaign?.name && (
                <Typography variant='h5' sx={{ color: '#0b2a4a', marginTop: 1.5 }}>
                  {campaign?.name}
                </Typography>
              )}
              <StyledTextButton
                size='small'
                sx={{
                  marginTop: 2,
                  color: '#0b2a4a',
                  border: '2px solid #0b2a4a',
                  paddingX: '20px',
                }}
              >
                Ver produto
              </StyledTextButton>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography sx={{ color: '#0b2a4a', textAlign: 'center' }}>Não há campanhas ativas.</Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CampaignsPage;
