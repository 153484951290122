import { Box, Typography } from "@mui/material";
import { imageOnError } from "../../../../helpers";

const SummaryProductInfo = ({ images, title, description, reference, size }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box component="img" src={images[0]} onError={imageOnError} width="100px" alt={title} sx={{ marginRight: "10px" }} />

      <Box
        sx={{
          textAlign: "left",
        }}
      >
        <Typography fontWeight={700}>{`${title} ${description}`}</Typography>

        <Typography>{`Código: ${reference}`}</Typography>

        <Typography>{size && `Tamanho: ${size || ""}`}</Typography>
      </Box>
    </Box>
  );
};

export default SummaryProductInfo;
