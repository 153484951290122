import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import STORES from "../../../../constants/stores";

const DeliveryAtStore = ({ disabled, store, onStoreChange }) => {
  const showPickupPointsOnly = STORES.filter((store) => store.pickupPoint === true);
  return (
    <FormControl margin="normal">
      <InputLabel>Selecione uma loja</InputLabel>

      <Select
        disabled={disabled}
        value={disabled ? "" : store}
        label="Selecione uma loja"
        onChange={onStoreChange}
        sx={{
          backgroundColor: "#fff",
        }}
      >
        {showPickupPointsOnly.map(({ title }) => (
          <MenuItem key={title} value={title}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DeliveryAtStore;
