import { Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const AboutUs = () => {
	return (
		<Grid item xs={10} sm={2.5} md={2}>
			<Typography variant="h5" gutterBottom>
				Sobre a Momel
			</Typography>

			<Typography
				gutterBottom
				component={RouterLink}
				to="/quemsomos"
				sx={{
					display: 'block',
					textDecoration: 'none',
					color: 'inherit',
				}}
			>
				Quem Somos
			</Typography>

			<Typography gutterBottom>
				<Link
					href="/catalogos"
					underline="none"
					rel="noreferrer"
					title='Catálogos'
				>
					Catálogos
				</Link>
			</Typography>

			<Typography
				gutterBottom
				component={RouterLink}
				to="/contactos"
				sx={{
					display: 'block',
					textDecoration: 'none',
					color: 'inherit',
				}}
			>
				Contactos
			</Typography>
		</Grid>
	);
};

export default AboutUs;
