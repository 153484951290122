import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'product',

  initialState: {
    product: {},
    userSelections: [],
  },

  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload;
    },

    resetUserSelection: (state) => {
      state.userSelections = [];
    },

    addUserSelection: (state, { payload }) => {
      const { type, quantity, price, pricePerBox, _id, package_quantity, measure_unit } =
        payload;

      const filteredUserSelection = state.userSelections.filter(
        (userSelection) => _id !== userSelection.id
      );

      const userSelection = {
        type,
        quantity,
        price,
        pricePerBox,
        id: _id,
        unitsPerBox: package_quantity,
        measure_unit,
      };

      state.userSelections = [...filteredUserSelection, userSelection];
    },
  },
});

export const { setProduct, resetUserSelection, addUserSelection } =
  productSlice.actions;

export default productSlice.reducer;
