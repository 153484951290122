import { Box, Typography, useMediaQuery } from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { StyledTextButton } from '../../../styles';
import PromoCode from './PromoCode';
import SummaryTable from './SummaryTable/SummaryTable';

const CartSummary = ({ setActiveStep, totalPrice }) => {
  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  const smallAndMediumDevicesBreakpoint = useMediaQuery('(max-width:768px)');

  return (
    <>
      <Typography
        variant='h5'
        sx={{ marginY: '60px', textAlign: 'center', color: '#0b2a4a' }}
      >
        Resumo do Carrinho
      </Typography>

      <SummaryTable />

      <Box
        sx={{
          marginY: '40px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: smallDevicesBreakpoint && 'column',
        }}
      >
        <PromoCode />

        <Typography variant='h6' sx={{ marginLeft: 'auto', color: '#0b2a4a' }}>
          Total da encomenda:
          {smallAndMediumDevicesBreakpoint ? (
            <Typography sx={{ textAlign: 'end', fontSize: '20px' }}>
              <strong>{formatCurrency(totalPrice)}</strong>
            </Typography>
          ) : (
            <strong>&nbsp;{formatCurrency(totalPrice)}</strong>
          )}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <StyledTextButton
          variant='contained'
          color='secondaryColorButton'
          href='/'
          sx={{ marginLeft: 'auto' }}
        >
          Voltar à Loja
        </StyledTextButton>

        <StyledTextButton
          variant='contained'
          onClick={() => setActiveStep(1)}
          sx={{ marginLeft: '10px' }}
        >
          Continuar
        </StyledTextButton>
      </Box>
    </>
  );
};

export default CartSummary;
