import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import ProductCard from '../ProductCard';

const ProductRelated = ({ related }) => {
  return (
    <Box sx={{ marginTop: '40px' }}>
      <Typography variant='h5' gutterBottom sx={{ color: '#0b2a4a' }}>
        Produtos Relacionados
      </Typography>

      <Grid
        container
        sx={{
          marginX: 0,
          marginY: '30px',
          gap: '18px',
        }}
      >
        {related.map((product) => (
          <Grid item xs={12} sm={5} md key={product.id}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductRelated;
