import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

const Brands = () => {
  const brands = useSelector((state) => state.homepage.brands);

  const navigate = useNavigate();

  function navigateToSearchPage(name) {
    navigate({
      pathname: '/pesquisa',
      search: createSearchParams({
        query: name,
      }).toString(),
    });
  }

  return (
    <Container sx={{ marginTop: '60px' }}>
      <Typography variant='h5' gutterBottom sx={{ color: '#0b2a4a' }}>
        Marcas
      </Typography>

      <Typography>As líderes do mercado na mesma plataforma.</Typography>

      {brands && (
        <Grid
          container
          sx={{
            marginX: 0,
            marginY: '30px',
            gap: '18px',
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'normal' },
          }}
        >
          {brands.map(({ image, _id, name }) => (
            <Grid key={_id} item xs sx={{ cursor: 'pointer' }}>
              <img
                src={image}
                alt=''
                onClick={() => navigateToSearchPage(name)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Brands;
