import { Box, Typography, useMediaQuery } from '@mui/material';
import { StyledFormTextfield, StyledTextButton } from '../../styles';

const BudgetRequestForm = () => {
  const smallAndMediumDevicesBreakpoint = useMediaQuery('(max-width:768px)');

  return (
    <Box
      sx={{
        textAlign: 'center',
        marginY: '20px',
      }}
    >
      <Typography gutterBottom variant='h4' fontWeight={700} color='#0b2a4a'>
        Pedido de Orçamento
      </Typography>

      <Typography gutterBottom>
        Mais informações? Preencha o seguinte formulário. <br /> Entraremos em
        contacto com a maior brevidade possível.
      </Typography>

      <Box
        sx={{
          width: smallAndMediumDevicesBreakpoint ? '80%' : '700px',
          margin: '30px auto',
        }}
      >
        <Box
          sx={{
            marginY: '10px',
            display: 'flex',
            flexDirection: smallAndMediumDevicesBreakpoint ? 'column' : 'row',
            gap: '10px',
          }}
        >
          <StyledFormTextfield label='Nome' sx={{ flex: 1.6 }} />

          <StyledFormTextfield label='Contacto' type='tel' sx={{ flex: 1 }} />

          <StyledFormTextfield label='Email' type='email' sx={{ flex: 1.6 }} />
        </Box>

        <StyledFormTextfield multiline fullWidth minRows={3} label='Mensagem' />

        <StyledTextButton sx={{ marginY: '10px' }} variant='contained'>
          Submeter
        </StyledTextButton>
      </Box>
    </Box>
  );
};

export default BudgetRequestForm;
