import { Grid, Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { StyledTextButton } from '../../../styles';

const SubcategoriesNavBar = ({ category }) => {
	const [isOpen, setIsOpen] = useState(false);

	const subcategoryRef = useRef(null);

	const allSubcategories = {
		id: 9999,
		url: '',
		text: 'Ver todas',
	};

	const subcategories = [
		...category.subcategories.slice(0, 5),
		allSubcategories,
	];

	return (
		<Grid item sx={{ marginX: '10px' }}>
			<StyledTextButton
				ref={subcategoryRef}
				color="secondaryColorButton"
				onClick={() => setIsOpen(true)}
				sx={{
					borderRadius: '0px',
					fontWeight: '700',
					'&:hover': {
						backgroundColor: '#fff',
						color: '#0b2a4a',
					},
				}}
			>
				{category.text}
			</StyledTextButton>

			<Menu
				anchorEl={subcategoryRef.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				keepMounted
				open={isOpen}
				onClose={() => setIsOpen(false)}
			>
				{subcategories.map((subcategory) => {
					const isAllSubcategories = subcategory.id === 9999;

					return (
						<MenuItem
							key={`subcategory-${subcategory.id}`}
							component={Link}
							to={`categoria/${encodeURIComponent(
								category.url
							)}/${encodeURIComponent(subcategory.url)}`}
							onClick={() => setIsOpen(false)}
							sx={{
								fontSize: 14,
								fontWeight: isAllSubcategories && 'bold',
							}}
						>
							{subcategory.text}
						</MenuItem>
					);
				})}
			</Menu>
		</Grid>
	);
};

export default SubcategoriesNavBar;
