import { Box, Button, Divider, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addUserSelection } from '../../../state/productSlice';
import { StyledTableSelect } from '../../../styles';

const QuantitySelector = ({
  variant,
  selectForCart,
  isSingleVariant,
  setIsOutOfStock,
}) => {
  const [quantity, setQuantity] = useState(0);

  const [type, setType] = useState('unit');

  const dispatch = useDispatch();

  const { id, stock, unitsPerBox, minQuantity, units , measure_unit} = variant;
  const isUnit = type === 'unit';

  useEffect(() => {
    const userSelection = {
      ...variant,
      type,
      quantity,
      measure_unit
    };

    dispatch(addUserSelection(userSelection));
  }, [dispatch, quantity, type, variant, measure_unit]);

  useEffect(() => {
    if (isSingleVariant && quantity === 0) increment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingleVariant]);

  function decrement(id) {
    const newQuantity = isUnit ? quantity - minQuantity : quantity - 1;

    const stillHasStock =
      (isUnit && newQuantity < stock) || newQuantity * unitsPerBox < stock;

    if (stillHasStock) setIsOutOfStock(false);

    setQuantity(newQuantity);

    const productQuantity = {
      units: isUnit ? newQuantity : 0,
      boxes: isUnit ? 0 : newQuantity,
    };

    const newProduct = { ...variant, quantity: productQuantity, measure_unit };

    selectForCart(newProduct);
  }

  function increment(id) {
    const newQuantity = isUnit ? quantity + minQuantity : quantity + 1;

    const notEnoughStock =
      (isUnit && newQuantity > stock) ||
      (!isUnit && newQuantity * unitsPerBox > stock);

    if (notEnoughStock) setIsOutOfStock(true);

    setQuantity(newQuantity);

    const productQuantity = {
      units: isUnit ? newQuantity : 0,
      boxes: isUnit ? 0 : newQuantity,
    };

    const newProduct = { ...variant, quantity: productQuantity, measure_unit };

    selectForCart(newProduct);
  }

  function changeType(e) {
    setType(e.target.value);

    const isBox = e.target.value === 'box';

    setQuantity(isBox ? 1 : minQuantity);

    const notEnoughStock =
      (isUnit && quantity > stock) || (isBox && unitsPerBox > stock);

    if (notEnoughStock) {
      setIsOutOfStock(true);
    } else setIsOutOfStock(false);

    const productQuantity = {
      units: isBox ? 0 : minQuantity,
      boxes: isBox ? 1 : 0,
    };

    const newProduct = { ...variant, quantity: productQuantity, measure_unit };

    selectForCart(newProduct);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        disabled={quantity < 1}
        sx={{ minWidth: '30px' }}
        onClick={() => decrement(id)}
      >
        -
      </Button>

      <Box component='span' sx={{ minWidth: '20px', textAlign: 'center' }}>
        {quantity}
      </Box>

      <Button sx={{ minWidth: '30px' }} onClick={() => increment(id)}>
        +
      </Button>

      <Divider orientation='vertical' variant='middle' flexItem />

      <StyledTableSelect
        size='small'
        value={type}
        onChange={changeType}
        displayEmpty
        autoWidth
        sx={{ minWidth: '92px', fontSize: '12px' }}
      >
        <MenuItem value='unit'>{units}</MenuItem>
        {unitsPerBox && <MenuItem value='box'>EMB</MenuItem>}
      </StyledTableSelect>
    </Box>
  );
};

export default QuantitySelector;
