const STORES = [
  {
    title: "Momel - Sede Amarante",
    address: {
      street: "Travessa Momel 269,",
      zipCode: "4600-216",
      city: "Amarante",
      country: "Portugal",
    },
    phone: "+351 255 410 140",
    mobile: "",
    email: "geral@momel.pt",
    pickupPoint: true,
  },

  {
    title: "Momel Porto",
    address: {
      street: "Estrada da Circunvalação 10492, Senhora da Hora,",
      zipCode: "4460-280",
      city: "Porto",
      country: "",
    },
    phone: "+351 229 518 816",
    mobile: "+351 917 340 477",
    email: "canope@momel.pt",
    pickupPoint: true,
  },

  {
    title: "Momel Rebordosa",
    address: {
      street: "Rua Prof.ª D.ª Maria dos Santos 52,",
      zipCode: "4585-451",
      city: "Rebordosa",
      country: "",
    },
    phone: "+351 224 443 043",
    mobile: "+351 919 737 317",
    email: "rebordosa@momel.pt",
    pickupPoint: true,
  },

  {
    title: "Exposição de Sanitários [Ponte Pego]",
    address: {
      street: "Rua da Ponte de Pego 477, Edifício Portas de Amarante - Telões,",
      zipCode: "4600-754",
      city: "Amarante",
      country: "",
    },
    phone: "+351 255 432 184",
    mobile: "+351 918 883 671",
    email: "pontepego@momel.pt",
    pickupPoint: false,
  },

  {
    title: "Momel Felgueiras",
    address: {
      street: "Avenida Dr. Ribeiro Magalhães 967,",
      zipCode: "4610-108",
      city: "Felgueiras",
      country: "",
    },
    phone: "+351 255 312 706",
    mobile: "+351 912 159 497",
    email: "felgueiras@momel.pt",
    pickupPoint: true,
  },

  {
    title: "Momel Vila Real",
    address: {
      street: "Avenida da Noruega 9009 Lote 5 - Loja 2,",
      zipCode: "5000-668",
      city: "Vila Real",
      country: "",
    },
    phone: "",
    mobile: "+351 966 747 039",
    email: "vilareal@momel.pt",
    pickupPoint: true,
  },

  {
    title: "AgroMomel",
    address: {
      street: "Fontaínhas Cepelos,",
      zipCode: "4600-216",
      city: "Amarante",
      country: "",
    },
    phone: "+351 255 410 140",
    mobile: "+351 919 447 351",
    email: "brico@momel.pt",
    pickupPoint: false,
  },

  {
    title: "Momel Luanda",
    address: {
      street: "Rua 21 de Janeiro 12, Bairro Morro Bento Samba,",
      zipCode: "",
      city: "Luanda",
      country: "",
    },
    phone: "+244 923 772 444",
    mobile: "",
    email: "geral@angola.momel.pt",
    pickupPoint: false,
  },
];

export default STORES;
