import { LoadingButton } from '@mui/lab';
import { Box, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/login';
import {
  setAddresses,
  setAdmin,
  setCompanyInfo,
  setPersonalInfo,
} from '../../state/userSlice';
import { StyledFormTextfield, StyledTextButton } from '../../styles';

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['userToken']);

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({
      ...values,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!userData.email || !userData.password) {
      setIsLoading(false);

      return;
    }

    login(userData)
      .then(({ token, user, expiresIn }) => {
        const expiresInSeconds = parseInt(expiresIn.charAt(0)) * 24 * 60 * 60;

        setCookie('userToken', token, {
          path: '/',
          maxAge: expiresInSeconds,
        });

        dispatch(setCompanyInfo(user.companyInfo));

        dispatch(setPersonalInfo(user.personalInfo));

        dispatch(setAddresses(user.addresses));

        dispatch(setAdmin(user.isAdmin));

        navigate('/', { replace: true });
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status >= 400) {
            setError(true);

            setErrorMessage(msg);
          }
          setIsLoading(false);
        }
      );
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <StyledFormTextfield
          fullWidth
          label='Introduza o seu email'
          type='email'
          name='email'
          required
          value={userData.email}
          onChange={handleChange}
        />

        <StyledFormTextfield
          fullWidth
          label='Introduza a sua palavra-passe'
          type='password'
          name='password'
          required
          value={userData.password}
          onChange={handleChange}
        />

        {isLoading ? (
          <LoadingButton loading />
        ) : (
          <StyledTextButton variant='contained' type='submit'>
            Iniciar Sessão
          </StyledTextButton>
        )}
      </Box>

      <Snackbar
        autoHideDuration={6000}
        message={errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setError(false);
        }}
        ContentProps={{ sx: { backgroundColor: '#ff5959' } }}
      />
    </>
  );
};

export default LoginForm;
