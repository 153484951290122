import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { StyledTextButton } from '../../styles';

const OrderConfirmation = () => {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <IconContext.Provider
        value={{ style: { color: '#4FD290', marginBottom: '40px' } }}
      >
        <BsCheckCircle size={64} />
      </IconContext.Provider>

      <Typography gutterBottom sx={{ fontWeight: 700 }}>
        A sua encomenda foi finalizada com sucesso.
      </Typography>

      <Typography gutterBottom>
        Entraremos em contacto para agendar a entrega dos produtos.
      </Typography>

      <Box
        sx={{
          marginY: '40px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <StyledTextButton
          variant='contained'
          href='/'
          sx={{ textAlign: 'center' }}
        >
          Procurar mais Produtos
        </StyledTextButton>

        <StyledTextButton
          variant='contained'
          href='/conta/encomendas'
          sx={{ marginLeft: '10px', textAlign: 'center' }}
        >
          Ir para as Encomendas
        </StyledTextButton>
      </Box>
    </Box>
  );
};

export default OrderConfirmation;
