import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import CustomerSupport from './CustomerSupport';
import FooterMobileAccordion from './FooterMobileAccordion';
import SocialMedia from './SocialMedia';

const Footer = () => {
  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  return (
    <Box
      sx={{
        minHeight: '400px',
        marginTop: smallDevicesBreakpoint ? '40px' : '80px',
        color: '#0b2a4a',
      }}
    >
      <Grid
        container
        sx={{
          marginBottom: smallDevicesBreakpoint ? '20px' : '60px',
          gap: '20px',
          justifyContent: 'center',
        }}
      >
        <SocialMedia />

        {smallDevicesBreakpoint ? (
          <>
            <FooterMobileAccordion />
          </>
        ) : (
          <>
            <AboutUs />

            <CustomerSupport />

            <ContactUs />
          </>
        )}
      </Grid>

      {!smallDevicesBreakpoint && <Divider sx={{ margin: '24px' }} />}

      <Typography
        align='center'
        sx={{ paddingBottom: '20px' }}
      >
        Momel S.A. © 2022.&nbsp;
        <Typography
          component='a'
          href='https://www.bdcadigital.com/plataforma-de-ecommerce-b2b'
          title='Powered by BDCA Digital'
          variant='caption'
          fontWeight={700}
          target='_blank'
          sx={{ color: 'black', textDecoration: 'none', transition: 'opacity 200ms ease', '&:hover': { opacity: '.75' } }}
        >
          B2B Platform by BDCA Digital
        </Typography>
      </Typography>
    </Box>
  );
};

export default Footer;
