import { Box } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { updateUser } from '../../../api/user';
import { setPersonalInfo } from '../../../state/userSlice';
import { StyledTextButton, StyledTextfield } from '../../../styles';

const PersonalInfo = () => {
  const personalInfo = useSelector((state) => state.user.personalInfo);

  const { name, phone, email } = personalInfo;

  const [userData, setUserData] = useState({ name, phone, email });

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [buttonText, setButtonText] = useState('Editar Informações');

  const dispatch = useDispatch();

  const [smallDevicesBreakpoint] = useOutletContext();

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({ ...values, [name]: value }));
  }

  function handleEdit() {
    const buttonText = isReadOnly
      ? 'Guardar Informações'
      : 'Editar Informações';

    setButtonText(buttonText);

    setIsReadOnly((prevState) => !prevState);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!userData) return;

    updateUser(userData).then(({ user: { personalInfo } }) => {
      dispatch(setPersonalInfo(personalInfo));
    });
  }

  return (
    <Box
      component='form'
      sx={{
        width: smallDevicesBreakpoint ? '100%' : '50%',
        marginBottom: '10px',
      }}
      onSubmit={handleSubmit}
    >
      <StyledTextfield
        fullWidth
        type='text'
        name='name'
        label='Nome'
        value={userData.name}
        onChange={handleChange}
        inputProps={{ readOnly: isReadOnly }}
        error={!isReadOnly}
      />

      <StyledTextfield
        fullWidth
        label='Telemóvel'
        type='text'
        name='phone'
        value={userData.phone}
        onChange={handleChange}
        inputProps={{ readOnly: isReadOnly }}
        error={!isReadOnly}
      />

      <StyledTextfield
        fullWidth
        label='Email'
        type='email'
        name='email'
        value={userData.email}
        onChange={handleChange}
        inputProps={{ readOnly: isReadOnly }}
        error={!isReadOnly}
      />

      <StyledTextButton
        variant='contained'
        type={!isReadOnly ? 'button' : 'submit'}
        onClick={handleEdit}
        sx={{ marginY: '10px' }}
      >
        {buttonText}
      </StyledTextButton>
    </Box>
  );
};

export default PersonalInfo;
