import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { updateAddress } from '../../../api/user';
import { setAddresses } from '../../../state/userSlice';
import { StyledTextButton, StyledTextfield } from '../../../styles';

const AddressesForm = () => {
  const [smallDevicesBreakpoint] = useOutletContext();

  const addresses = useSelector((state) => state.user.addresses);

  const presentationalAddresses = addresses.map((address) => {
    const { city, country, zipCode, street } = address;

    const id = `${street}${city}${zipCode}${country}`;

    const oldStreet = `${street}`;

    return { ...address, id, oldStreet };
  });

  const [userAddresses, setUserAddresses] = useState(presentationalAddresses);

  const [shownAddress, setShownAddress] = useState(userAddresses[0]);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [buttonText, setButtonText] = useState('Editar Informações');

  const [error, setError] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setAddresses(addresses));
  }, [addresses, dispatch]);

  function handleChange(e) {
    const { value } = e.target;

    const newAddress = userAddresses.find(({ id }) => id === value);

    setShownAddress(newAddress);
  }

  function handleEditInfo(e) {
    const { name, value } = e.target;

    setShownAddress((values) => ({ ...values, [name]: value }));
  }

  function handleEdit() {
    const buttonText = isReadOnly
      ? 'Guardar Informações'
      : 'Editar Informações';

    setButtonText(buttonText);

    setIsReadOnly((prevState) => !prevState);
  }

  function handleSubmit(e) {
    e.preventDefault();

    updateAddress(shownAddress)
      .then((addresses) => {
        dispatch(setAddresses(addresses));

        const presentationalAddresses = addresses.map((address) => {
          const { city, country, zipCode, street } = address;

          const id = `${street}${city}${zipCode}${country}`;

          const oldStreet = `${street}`;

          return { ...address, id, oldStreet };
        });

        setUserAddresses(presentationalAddresses);
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status > 401) {
            const errorMessage = errors.reduce(
              (prevValue, { msg, value }) => `${prevValue} ${value}: ${msg}.
              `,
              ''
            );

            setError(true);

            setErrorMessage(errorMessage);
          }
        }
      );
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '100%', sm: '50%' },
      }}
    >
      <FormControl fullWidth disabled={!isReadOnly}>
        <InputLabel>Morada de Entrega</InputLabel>

        <Select
          label='Morada de Entrega'
          name='street'
          value={shownAddress?.id}
          onChange={handleChange}
          sx={{
            backgroundColor: '#fff',
          }}
        >
          {userAddresses.map((address) => {
            const { street, id } = address;

            return (
              <MenuItem key={id} value={id}>
                {street}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <StyledTextfield
        fullWidth
        multiline
        type='text'
        name='street'
        label='Morada Completa'
        value={shownAddress.street}
        onChange={handleEditInfo}
        inputProps={{ readOnly: isReadOnly }}
        InputLabelProps={{ shrink: true }}
        error={!isReadOnly}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: smallDevicesBreakpoint && 'column',
          gap: '10px',
        }}
      >
        <StyledTextfield
          type='text'
          name='zipCode'
          label='Código Postal'
          value={shownAddress.zipCode}
          onChange={handleEditInfo}
          inputProps={{ readOnly: isReadOnly }}
          InputLabelProps={{ shrink: true }}
          error={!isReadOnly}
          sx={{ flex: 1 }}
        />

        <StyledTextfield
          type='text'
          name='city'
          label='Localidade'
          value={shownAddress.city}
          onChange={handleEditInfo}
          inputProps={{ readOnly: isReadOnly }}
          InputLabelProps={{ shrink: true }}
          error={!isReadOnly}
          sx={{ flex: 1 }}
        />
      </Box>

      <Box
        sx={{
          width: smallDevicesBreakpoint ? '100%' : '49%',
          marginBottom: '10px',
        }}
      >
        <StyledTextfield
          fullWidth
          type='text'
          name='country'
          label='País'
          value={shownAddress.country}
          onChange={handleEditInfo}
          inputProps={{ readOnly: isReadOnly }}
          InputLabelProps={{ shrink: true }}
          error={!isReadOnly}
        />
      </Box>

      <StyledTextButton
        variant='contained'
        type={!isReadOnly ? 'button' : 'submit'}
        onClick={handleEdit}
        disabled={
          !shownAddress.street ||
          !shownAddress.zipCode ||
          !shownAddress.city ||
          !shownAddress.country
        }
        sx={{
          width: smallDevicesBreakpoint ? '172px' : null,
          marginY: '10px',
        }}
      >
        {buttonText}
      </StyledTextButton>

      <Snackbar
        autoHideDuration={10000}
        message={errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setError(false);
        }}
        ContentProps={{
          sx: { backgroundColor: '#ff5959', whiteSpace: 'pre-wrap' },
        }}
      />
    </Box>
  );
};

export default AddressesForm;
