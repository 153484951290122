import React from 'react';
import { Link } from 'react-router-dom';
import { StyledTextButton } from '../../../styles';

const CampaignsButton = () => {
  return (
    <StyledTextButton
      component={Link}
      to='/campanhas'
      sx={{
        marginX: '10px',
        borderRadius: '0px',
        fontWeight: '700',
        color: '#ff5959',
        '&:hover': {
          backgroundColor: '#fff',
          color: '#ff5959',
        },
      }}
    >
      Campanhas
    </StyledTextButton>
  );
};

export default CampaignsButton;
