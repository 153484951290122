import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { StyledTableCell } from '../../../styles';

const OrdersTable = ({ shownOrders }) => {
  return (
    <TableContainer component={Paper} sx={{ marginY: '20px' }}>
      <Table size='small' id='orders-table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Número</StyledTableCell>

            <StyledTableCell>Data</StyledTableCell>

            <StyledTableCell>Estado</StyledTableCell>

            <StyledTableCell>Quantidade</StyledTableCell>

            <StyledTableCell>Total</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {shownOrders.map((order) => {
            const { reference, date, status, total, items } = order;

            const quantity = items.reduce(
              (previousValue, { unitsPerBox, quantity: { units, boxes } }) =>
                (previousValue += units + boxes * unitsPerBox),
              0
            );

            return (
              <TableRow
                key={reference}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell>{reference}</StyledTableCell>

                <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                  {date}
                </StyledTableCell>

                <StyledTableCell>{status}</StyledTableCell>

                <StyledTableCell>{quantity}</StyledTableCell>

                <StyledTableCell>{formatCurrency(total)}</StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;
