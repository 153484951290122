const FILTERS_TITLES = {
  subcategories: 'SUBCATEGORIA',
  brands: 'MARCA',
  sizes: 'DIMENSÕES',
};

function mapProduct(product) {
  return {
    ...product,
    id: product._id,
    title: product.title,
    date: product.erp_update_date,
    datasheet: product.tech_doc,
    variants: product.variants.map((variant) => ({
      ...variant,
      id: variant.parent_id,
      date: variant.erp_update_date,
      images: [variant.image],
      pricePerUnit: variant.price,
      unitsPerBox: variant.package_quantity,
      minQuantity: variant.minimum_quantity,
      units: variant.measure_unit,
    })),
  };
}

function mapCategory(category) {
  return {
    ...category,
    id: category.erp_id,
    text: category.name,
    date: category.erp_update_date,
    url: category.name,
    subcategories: category.subcategories.map((subcategory) => ({
      ...subcategory,
      id: subcategory.erp_id,
      text: subcategory.name.toString(),
      date: subcategory.erp_update_date,
      url: subcategory.name,
    })),
  };
}

function mapFilters(filters) {
  return Object.entries(filters).map(([key, value]) => ({
    id: key,
    title: FILTERS_TITLES[key],
    options: value,
  }));
}

export { mapProduct, mapCategory, mapFilters };
