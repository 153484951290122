import { AccordionDetails, Box, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledFormInputLabel,
} from '../../styles';

const FooterMobileAccordion = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box>
      <StyledAccordion
        expanded={expanded === 'aboutUs'}
        onChange={handleChange('aboutUs')}
      >
        <StyledAccordionSummary
          aria-controls='aboutUs-content'
          id='aboutUs-header'
        >
          <StyledFormInputLabel>Mais sobre a Momel</StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails>
          <Typography
            gutterBottom
            component={RouterLink}
            to='/quemsomos'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: '#0b2a4a',
            }}
          >
            Quem Somos
          </Typography>

          <Typography gutterBottom>
            <Link
              href='/catalogos'
              underline='none'
              rel='noreferrer'
              title='Catálogos'
            >
              Catálogos
            </Link>
          </Typography>

          <Typography
            gutterBottom
            component={RouterLink}
            to='/contactos'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: '#0b2a4a',
            }}
          >
            Contactos
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === 'customerSupport'}
        onChange={handleChange('customerSupport')}
      >
        <StyledAccordionSummary
          aria-controls='customerSupport-content'
          id='customerSupport-header'
        >
          <StyledFormInputLabel>Apoio ao cliente</StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails>
          <Typography
            gutterBottom
            component={RouterLink}
            to='/termos'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Termos e Condições
          </Typography>

          <Typography
            gutterBottom
            component={RouterLink}
            to='/privacidade'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Política de Privacidade
          </Typography>

          <Typography
            gutterBottom
            component={RouterLink}
            to='/condicoesvenda'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Condições Gerais de Venda
          </Typography>

          <Typography gutterBottom>
            <Link
              href='https://www.livroreclamacoes.pt/Inicio/'
              target='_blank'
              rel='noreferrer'
              underline='none'
            >
              Livro de Reclamações
            </Link>
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === 'contactUs'}
        onChange={handleChange('contactUs')}
      >
        <StyledAccordionSummary
          aria-controls='contactUs-content'
          id='contactUs-header'
        >
          <StyledFormInputLabel>Contacte-nos</StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails>
          <Typography gutterBottom>
            Travessa Momel nº269, 4600-216 Amarante Portugal
          </Typography>

          <Typography gutterBottom>
            <strong>Telf.</strong> +351 234 940 250
          </Typography>

          <Typography gutterBottom>
            <strong>Fax.</strong> +351 234 940 266
          </Typography>

          <Typography>
            <strong>Email.</strong> geral@momel.pt
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default FooterMobileAccordion;
