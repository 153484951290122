import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  removeProductFromCart,
  updateCartProducts,
} from '../../../../api/checkout';
import {
  removeFromCart,
  removeQuantityFromCartItem,
  setCart,
  setCartTotal,
  updateCart,
} from '../../../../state/cartSlice';
import EliminateFromCartButton from './EliminateFromCartButton';

const SummaryQuantitySelector = ({
  quantity,
  minQuantity,
  unit,
  id,
  reference,
  stock,
  unitsPerBox,
}) => {
  const cart = useSelector((state) => state.cart.cart);

  const { units, boxes } = quantity;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function decrement(id, reference, unitType) {
    // store function that checks cart and verifies that it can decrement product type
    // store will rerender parent component
    const isUnit = unitType === 'units';

    const step = isUnit ? minQuantity : 1;

    const product = cart.find(
      ({ reference: newReference }) => newReference === reference
    );

    const newProduct = {
      ...product,
      quantity: { [unitType]: step },
    };

    dispatch(removeQuantityFromCartItem([newProduct]));

    const updatedProduct = {
      ...product,
      quantity: { ...quantity, [unitType]: quantity[unitType] - step },
    };

    if (!updatedProduct.quantity[unitType]) {
      removeProductFromCart(reference, unitType)
        .then(({ total: totalPrice }) => {
          dispatch(setCartTotal(totalPrice));
        })
        .catch(({ response: { status } }) => {
          if (status === 401) navigate('/login');
        });
    } else {
      updateCartProducts([updatedProduct])
        .then(({ total: totalPrice }) => {
          dispatch(setCartTotal(totalPrice));
        })
        .catch(({ response: { status } }) => {
          if (status === 401) navigate('/login');
        });
    }
  }

  function increment(id, reference, unitType) {
    const isUnit = unitType === 'units';

    const step = isUnit ? minQuantity : 1;

    const product = cart.find(
      ({ reference: newReference }) => newReference === reference
    );

    const newProduct = { ...product, quantity: { [unitType]: step } };

    dispatch(updateCart([newProduct]));

    const updatedProduct = {
      ...product,
      quantity: { ...quantity, [unitType]: quantity[unitType] + step },
    };

    updateCartProducts([updatedProduct])
      .then(({ total: totalPrice }) => {
        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }

  function handleProductDelete(id, reference, unitType) {
    dispatch(removeFromCart({ id, reference, unitType }));

    removeProductFromCart(reference, unitType)
      .then(({ cart_items: cart, total: totalPrice }) => {
        dispatch(setCart(cart));

        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {Boolean(units) && (
          <>
            <Button
              onClick={() => decrement(id, reference, 'units')}
              sx={{ minWidth: '30px' }}
            >
              -
            </Button>

            <Box
              component='span'
              sx={{ minWidth: '20px', textAlign: 'center' }}
            >
              {units >= stock ? stock : units}
            </Box>

            <Button
              onClick={() => increment(id, reference, 'units')}
              sx={{ minWidth: '30px' }}
            >
              +
            </Button>
          </>
        )}

        {Boolean(units) && <Typography>{unit}</Typography>}

        {Boolean(units) && (
          <EliminateFromCartButton
            onDelete={() => handleProductDelete(id, reference, 'units')}
          />
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {Boolean(boxes) && (
          <>
            <Button
              sx={{ minWidth: '30px' }}
              onClick={() => decrement(id, reference, 'boxes')}
            >
              -
            </Button>

            <Box
              component='span'
              sx={{ minWidth: '20px', textAlign: 'center' }}
            >
              {boxes >= stock ? stock : boxes}
            </Box>

            <Button
              onClick={() => increment(id, reference, 'boxes')}
              sx={{ minWidth: '30px' }}
            >
              +
            </Button>
          </>
        )}

        {Boolean(boxes) && <Typography>Embalagem</Typography>}

        {Boolean(boxes) && (
          <EliminateFromCartButton
            onDelete={() => handleProductDelete(id, reference, 'boxes')}
          />
        )}
      </Box>
    </Box>
  );
};

export default SummaryQuantitySelector;
