import { Box, Chip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../constants/utils';
import { StyledOrderSummaryBox, StyledOrderSummaryText } from '../../../styles';

const OrderSummaryCard = ({ cart, tax, deliveryCost, voucherDiscount }) => {
  const voucherCode = useSelector((state) => state.cart.voucherCode);

  const [subtotal, setSubtotal] = useState(0);

  const orderSummary = [
    {
      text: 'Total de Unidades',
      value: cart.reduce(
        (
          previousValue,
          { package_quantity: unitsPerBox, quantity: { units, boxes } }
        ) => (previousValue += units + boxes * unitsPerBox),
        0
      ),
    },
    {
      text: 'Referências',
      value: cart.length,
    },
    {
      text: 'Subtotal',
      value: formatCurrency(subtotal),
    },
    {
      text: 'Envio',
      value: formatCurrency(deliveryCost),
    },
    { text: 'IVA (23 %)', value: formatCurrency(tax) },
    {
      text: 'Descontos',
      value: formatCurrency(voucherDiscount),
    },
  ];

  const subtotalRounded = Math.round((subtotal + Number.EPSILON) * 100) / 100;
  const taxRounded = Math.round((tax + Number.EPSILON) * 100) / 100;
  const total = subtotalRounded + taxRounded + deliveryCost - voucherDiscount;

  useEffect(() => {
    const subtotal = cart.reduce(
      (
        previousValue,
        { package_quantity: unitsPerBox, price: pricePerUnit, quantity }
      ) =>
        previousValue +
        (pricePerUnit * quantity.units +
          pricePerUnit * quantity.boxes * unitsPerBox),
      0
    );

    setSubtotal(subtotal);
  }, [cart]);

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '50%', md: '30%' },
        height: '100%',
        marginLeft: { xs: 0, md: '100px' },
        marginBottom: '40px',
        padding: '10px',
        border: '1px solid #D1D1D1',
        borderRadius: '10px',
        backgroundColor: '#fff',
      }}
    >
      <Typography
        variant='h6'
        sx={{
          marginBottom: '40px',
          color: '#0b2a4a',
          fontWeight: 700,
          textAlign: 'center',
        }}
      >
        Resumo da Encomenda
      </Typography>

      {orderSummary.map(({ text, value }) => (
        <StyledOrderSummaryBox key={text}>
          <StyledOrderSummaryText>{text}</StyledOrderSummaryText>

          <StyledOrderSummaryText color={text === 'Descontos' && '#B90000'}>
            {value}
          </StyledOrderSummaryText>
        </StyledOrderSummaryBox>
      ))}

      {voucherCode && (
        <Chip
          size='small'
          label={`Código promocional "${voucherCode}" aplicado`}
          sx={{
            maxWidth: '100%',
            color: '#0b2a4a',
            backgroundColor: '#0b2a4a4d',
          }}
        />
      )}

      <StyledOrderSummaryBox sx={{ marginTop: '50px', alignItems: 'baseline' }}>
        <StyledOrderSummaryText>Total</StyledOrderSummaryText>

        <Typography
          fontSize='26px'
          fontWeight={700}
          color='#0B2A4A'
        >
          {formatCurrency(total)}
        </Typography>
      </StyledOrderSummaryBox>
    </Box>
  );
};

export default OrderSummaryCard;
