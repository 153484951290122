import { LoadingButton } from '@mui/lab';
import { Box, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeLogin } from '../../../api/login';
import { StyledTextButton, StyledTextfield } from '../../../styles';

const AccessForm = () => {
  const personalInfo = useSelector((state) => state.user.personalInfo);

  const oldPassword = personalInfo.password;

  const [userData, setUserData] = useState({
    email: '',
    oldPassword,
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordValidationError, setPasswordValidationError] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [errorConfirmation, setErrorConfirmation] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [editedInfoConfirmation, setEditedInfoConfirmation] = useState(false);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [buttonText, setButtonText] = useState('Editar Informações');

  const validateInput = (e) => {
    let { name, value } = e.target;

    setPasswordValidationError((prev) => {
      const stateObj = { ...prev, [name]: '' };

      switch (name) {
        case 'newPassword':
          if (!value) {
            stateObj[name] = 'Por favor insira palavra-passe nova.';
          } else if (
            userData.confirmPassword &&
            value !== userData.confirmPassword
          ) {
            stateObj['confirmPassword'] =
              'Confirmação da palavra-passe não corresponde.';
          } else {
            stateObj['confirmPassword'] = userData.confirmPassword
              ? ''
              : passwordValidationError.confirmPassword;
          }
          break;

        case 'confirmPassword':
          if (!value) {
            stateObj[name] = 'Por favor confirme palavra-passe nova.';
          } else if (userData.newPassword && value !== userData.newPassword) {
            stateObj[name] = 'Confirmação da palavra-passe não corresponde.';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({ ...values, [name]: value }));

    validateInput(e);
  }

  function handleEdit() {
    const buttonText = isReadOnly
      ? 'Guardar Informações'
      : 'Editar Informações';

    setButtonText(buttonText);

    setIsReadOnly((prevState) => !prevState);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!userData.email || !userData.oldPassword || !userData.newPassword) {
      setIsLoading(false);

      return;
    }

    changeLogin(userData)
      .then(() => {
        setEditedInfoConfirmation(true);
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setErrorConfirmation(true);

            setErrorMessage(msg);
          }
        }
      )
      .then(() => {
        setIsLoading(false);

        setUserData({
          email: '',
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      });
  }

  return (
    <>
      <Box
        component='form'
        sx={{ width: { xs: '100%', sm: '50%' } }}
        onSubmit={handleSubmit}
      >
        <StyledTextfield
          fullWidth
          type='email'
          name='email'
          required
          label='Email de Registo'
          value={userData.email}
          onChange={handleChange}
          onBlur={validateInput}
          inputProps={{ readOnly: isReadOnly }}
          error={!isReadOnly}
        />

        <StyledTextfield
          fullWidth
          type='password'
          name='oldPassword'
          required
          label='Palavra-Passe atual'
          value={userData.oldPassword}
          onChange={handleChange}
          onBlur={validateInput}
          inputProps={{ readOnly: isReadOnly }}
          error={!isReadOnly}
        />

        <StyledTextfield
          fullWidth
          type='password'
          name='newPassword'
          required
          label='Palavra-Passe nova'
          value={userData.newPassword}
          onChange={handleChange}
          onBlur={validateInput}
          inputProps={{ readOnly: isReadOnly }}
          error={!isReadOnly}
        />
        {passwordValidationError.newPassword && (
          <Typography variant='caption' color='#ff5959'>
            {passwordValidationError.newPassword}
          </Typography>
        )}

        <StyledTextfield
          fullWidth
          type='password'
          name='confirmPassword'
          required
          label='Confirmar Palavra-Passe nova'
          value={userData.confirmPassword}
          onChange={handleChange}
          onBlur={validateInput}
          inputProps={{ readOnly: isReadOnly }}
          error={!isReadOnly}
        />
        {passwordValidationError.confirmPassword && (
          <Typography variant='caption' color='#ff5959'>
            {passwordValidationError.confirmPassword}
          </Typography>
        )}

        <Typography sx={{ marginY: '10px', fontSize: '12px' }}>
          * Campos de preenchimento obrigatório.
        </Typography>

        {isLoading ? (
          <LoadingButton loading />
        ) : (
          <StyledTextButton
            variant='contained'
            type={!isReadOnly ? 'button' : 'submit'}
            onClick={handleEdit}
            sx={{ marginY: '10px' }}
          >
            {buttonText}
          </StyledTextButton>
        )}
      </Box>

      {passwordValidationError ? (
        <Snackbar
          autoHideDuration={6000}
          message={errorMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={errorConfirmation}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setPasswordValidationError(false);
          }}
          ContentProps={{ sx: { backgroundColor: '#ff5959' } }}
        />
      ) : (
        <Snackbar
          autoHideDuration={5000}
          message='Nova palavra-passe definida'
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={editedInfoConfirmation}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setEditedInfoConfirmation(false);
          }}
          ContentProps={{ sx: { backgroundColor: '#4FD290' } }}
        />
      )}
    </>
  );
};

export default AccessForm;
