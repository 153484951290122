import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { StyledTableCell } from '../../../styles';

const ReceiptsTable = ({ receipts }) => {
  return (
    <TableContainer component={Paper} sx={{ marginY: '20px' }}>
      <Table size='small' id='receipts-table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Número</StyledTableCell>

            <StyledTableCell>Data</StyledTableCell>

            <StyledTableCell>Estado</StyledTableCell>

            <StyledTableCell>Data de vencimento</StyledTableCell>

            <StyledTableCell>Total</StyledTableCell>

            <StyledTableCell>Por Pagar</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {receipts.map(
            ({
              reference,
              date,
              dueDate,
              status,
              value: total,
              balance: pending,
            }) => (
              <TableRow
                key={`${reference}-${date}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell>{reference}</StyledTableCell>

                <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                  {date}
                </StyledTableCell>

                <StyledTableCell>{status}</StyledTableCell>

                <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                  {dueDate}
                </StyledTableCell>

                <StyledTableCell>{formatCurrency(total)}</StyledTableCell>

                <StyledTableCell>{formatCurrency(pending)}</StyledTableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiptsTable;
