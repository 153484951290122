import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isValidUrl } from '../constants/utils';

const CategoryPage = () => {
	const categories = useSelector((state) => state.categories.categories);

	const { categorySlug, subcategoriesSlug } = useParams();

	const navigate = useNavigate();

	const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

	if (!isValidUrl(categorySlug, subcategoriesSlug, categories))
		navigate('/', { replace: true });

	const category = categories.find(({ url }) => url === categorySlug);

	if (!category) {
		return null;
	}

	return (
		<Box sx={{ padding: '40px', backgroundColor: '#f7f7f7' }}>
			<Typography
				variant="h1"
				sx={{
					textAlign: 'center',
					marginBottom: '40px',
					color: '#0b2a4a',
				}}
			>
				{category.text}
			</Typography>

			<Grid
				container
				sx={{ marginY: '20px', gap: '24px', justifyContent: 'center' }}
			>
				{category.subcategories.map((subcategory) => (
					<Grid
						key={subcategory.id}
						item
						component={Link}
						to={`${encodeURIComponent(subcategory.url)}`}
						sx={{
							width: smallDevicesBreakpoint ? '90%' : '300px',
							height: '200px',
							padding: '20px',
							textDecoration: 'none',
							backgroundColor: '#fff',
							borderRadius: '6px',
							boxShadow: 2,
							'&:hover': {
								backgroundColor: '#eaeaea',
							},
							cursor: 'pointer',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography
							fontSize="24px"
							fontWeight="700"
							color="#0b2a4a"
							textAlign="center"
						>
							{subcategory.text}
						</Typography>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default CategoryPage;
