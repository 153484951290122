import {
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { StyledTextButton } from '../../../../styles';

const EliminateFromCartButton = ({ onDelete }) => {
  const [openFeedback, setOpenFeedback] = useState(false);

  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  return (
    <>
      <StyledTextButton
        onClick={() => setOpenFeedback(true)}
        sx={{ color: '#B10034' }}
      >
        Eliminar
      </StyledTextButton>

      <Dialog
        PaperProps={{
          sx: {
            width: smallDevicesBreakpoint ? '80%' : '50%',
            padding: '20px'
          },
        }}
        open={openFeedback}
        onClose={() => setOpenFeedback(false)}
      >
        <DialogContent sx={{ color: '#0B2A4A', fontWeight: 700 }}>
          Eliminar produto?
        </DialogContent>

        <DialogActions>
          <StyledTextButton
            variant='contained'
            color='secondaryColorButton'
            onClick={() => setOpenFeedback(false)}
          >
            Cancelar
          </StyledTextButton>

          <StyledTextButton
            variant='contained'
            onClick={(e) => {
              onDelete();
              setOpenFeedback(false);
            }}
          >
            Eliminar
          </StyledTextButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EliminateFromCartButton;
