import { Box, Tabs } from '@mui/material';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import { formatTextToTitleCase } from '../../constants/utils';
import { StyledSidebarTab } from '../../styles';
import AddShoppingListModal from './AddShoppingListModal';
import DeleteShoppingListModal from './DeleteShoppingListModal';

const ShoppingListNavMenu = ({
  index,
  setIndex,
  shoppingLists,
  smallAndMediumDevicesBreakpoint,
}) => {
  const [isAddShoppingListOpen, setIsAddShoppingListOpen] = useState(false);

  const [isDeleteShoppingListOpen, setIsDeleteShoppingListOpen] =
    useState(false);

  if (!shoppingLists) return null;

  return (
    <Box
      sx={{
        width: smallAndMediumDevicesBreakpoint ? '100%' : '300px',
        height: '100%',
        marginBottom: '20px',
        backgroundColor: '#fff',
        overflowY: 'auto',
      }}
    >
      <Tabs
        orientation={
          smallAndMediumDevicesBreakpoint ? 'horizontal' : 'vertical'
        }
        variant={smallAndMediumDevicesBreakpoint ? 'scrollable' : 'standard'}
        value={index}
        onChange={(e, newValue) => setIndex(newValue)}
        scrollButtons
        allowScrollButtonsMobile
      >
        {shoppingLists.map(({ name, _id }) => (
          <StyledSidebarTab
            key={_id}
            label={formatTextToTitleCase(name, [
              'de',
              'a',
              'com',
              'em',
              'por',
              'e',
            ])}
            sx={{
              alignItems: smallAndMediumDevicesBreakpoint ? 'center' : 'start',
            }}
          />
        ))}
      </Tabs>

      <Box
        sx={{
          display: 'flex',
          flexDirection: smallAndMediumDevicesBreakpoint ? 'row' : 'column',
        }}
      >
        <StyledSidebarTab
          label='Criar nova Lista de Compras'
          icon={<FiPlus size={smallAndMediumDevicesBreakpoint && 24} />}
          iconPosition='end'
          onClick={() => setIsAddShoppingListOpen(true)}
          sx={{
            flex: smallAndMediumDevicesBreakpoint && 1,
            width: '100%',
            marginY: '10px',
            opacity: 1,
            backgroundColor: '#f7f7f7',
            border: '4px solid #fff',
          }}
        />

        {Boolean(shoppingLists.length) && (
          <StyledSidebarTab
            onClick={() => setIsDeleteShoppingListOpen(true)}
            label='Apagar Lista de Compras'
            icon={
              <AiOutlineDelete size={smallAndMediumDevicesBreakpoint && 24} />
            }
            iconPosition='end'
            sx={{
              flex: smallAndMediumDevicesBreakpoint && 1,
              width: '100%',
              marginY: '10px',
              opacity: 1,
              backgroundColor: '#f7f7f7',
              border: '4px solid #fff',
            }}
          />
        )}
      </Box>

      <AddShoppingListModal
        isOpen={isAddShoppingListOpen}
        setIsOpen={setIsAddShoppingListOpen}
      />

      <DeleteShoppingListModal
        isOpen={isDeleteShoppingListOpen}
        setIsOpen={setIsDeleteShoppingListOpen}
        setIndex={setIndex}
      />
    </Box>
  );
};

export default ShoppingListNavMenu;
