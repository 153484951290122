import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { getUser } from '../api/user';
import UserAccountNavBar from '../components/UserAccount/UserAccountNavBar';
import {
  setAddresses,
  setCompanyInfo,
  setPersonalInfo,
} from '../state/userSlice';

const AccountPage = () => {
  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  const smallAndMediumDevicesBreakpoint = useMediaQuery('(max-width:768px)');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    getUser()
      .then((user) => {
        const { personalInfo, companyInfo, addresses } = user;

        dispatch(setPersonalInfo(personalInfo));

        dispatch(setCompanyInfo(companyInfo));

        dispatch(setAddresses(addresses));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: smallAndMediumDevicesBreakpoint ? '10px' : '40px',
        backgroundColor: '#f7f7f7',
        display: 'flex',
        flexDirection: smallAndMediumDevicesBreakpoint ? 'column' : 'row',
      }}
    >
      <UserAccountNavBar
        smallAndMediumDevicesBreakpoint={smallAndMediumDevicesBreakpoint}
      />

      <Outlet context={[smallDevicesBreakpoint]} />
    </Box>
  );
};

export default AccountPage;
