import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteShoppingList } from '../../api/user';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { StyledTextButton } from '../../styles';

const DeleteShoppingListModal = ({ isOpen, setIsOpen, setIndex }) => {
  const shoppingLists = useSelector(
    (state) => state.shoppingLists.shoppingLists
  );

  const [selectShoppingList, setSelectShoppingList] = useState(
    shoppingLists[0]?._id
  );

  const [hasConfirmation, setHasConfirmation] = useState(false);

  const [validationMessage, setValidationMessage] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function handleShoppingListDelete() {
    deleteShoppingList(selectShoppingList)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setHasConfirmation(true);

        setValidationMessage('Lista apagada');
      })
      .catch(
        ({
          response: {
            data: { message },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setValidationMessage(message);
          }
        }
      );

    setIsOpen(false);

    setIndex(0);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        PaperProps={{
          sx: {
            height: { xs: '50%', sm: '30%' },
            padding: '20px',
            backgroundColor: 'white',
          },
        }}
      >
        <DialogTitle sx={{ color: '#0B2A4A', fontWeight: 700 }}>
          Seleciona a lista de compras
        </DialogTitle>

        <DialogContent>
          <Select
            fullWidth
            size='small'
            value={selectShoppingList}
            onChange={(e) => setSelectShoppingList(e.target.value)}
            sx={{ backgroundColor: '#fff' }}
          >
            {shoppingLists.map(({ name, _id }) => (
              <MenuItem key={_id} value={_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <DialogActions>
          <StyledTextButton
            variant='contained'
            color='secondaryColorButton'
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </StyledTextButton>

          <StyledTextButton
            variant='contained'
            onClick={handleShoppingListDelete}
          >
            Apagar
          </StyledTextButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        autoHideDuration={4000}
        message={validationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={hasConfirmation}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setHasConfirmation(false);
        }}
        ContentProps={{
          sx: {
            backgroundColor:
              validationMessage === 'Lista apagada' ? '#4FD290' : '#ff5959',
          },
        }}
      />
    </>
  );
};

export default DeleteShoppingListModal;
