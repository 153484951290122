import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { StyledTextButton } from '../../../styles';
import AddressesForm from './AddressesForm';
import NewAddressModal from './NewAddressModal';

const AccountAddresses = () => {
  const addresses = useSelector((state) => state.user.addresses);

  const [smallDevicesBreakpoint] = useOutletContext();

  const [isCreateNewAddressOpen, setIsCreateNewAddressOpen] = useState(false);

  if (!addresses) return null;

  return (
    <Box
      sx={{
        flex: 3,
        marginX: '40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography gutterBottom color='#0b2a4a' fontWeight={700}>
        Livro de Endereços
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: smallDevicesBreakpoint && 'column',
        }}
      >
        <AddressesForm />

        <StyledTextButton
          onClick={() => setIsCreateNewAddressOpen(true)}
          variant='contained'
          color='secondaryColorButton'
          endIcon={<FiPlus size={12} />}
          sx={{
            width: smallDevicesBreakpoint ? '172px' : null,
            alignSelf: smallDevicesBreakpoint ? 'start' : 'end',
            marginLeft: smallDevicesBreakpoint ? null : 'auto',
            marginY: '10px',
          }}
        >
          Adicionar Endereço
        </StyledTextButton>
      </Box>

      <NewAddressModal
        isOpen={isCreateNewAddressOpen}
        setIsOpen={setIsCreateNewAddressOpen}
      />
    </Box>
  );
};

export default AccountAddresses;
