import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addAddress } from '../../../api/user';
import { setAddresses } from '../../../state/userSlice';
import { StyledTextButton, StyledTextfield } from '../../../styles';

const NewAddressModal = ({ isOpen, setIsOpen }) => {
  const [newDeliveryAddress, setNewDeliveryAddress] = useState({
    street: '',
    zipCode: '',
    city: '',
    country: '',
  });

  const [hasConfirmation, setHasConfirmation] = useState(false);

  const [validationMessage, setValidationMessage] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function handleEntering() {
    setNewDeliveryAddress({});
  }

  function handleChange(e) {
    const { name, value } = e.target;

    setNewDeliveryAddress((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!newDeliveryAddress) return;

    addAddress(newDeliveryAddress)
      .then((addresses) => {
        dispatch(setAddresses(addresses));

        setValidationMessage('Morada adicionada');

        setNewDeliveryAddress({
          street: '',
          zipCode: '',
          city: '',
          country: '',
        });

        setIsOpen(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status > 401) {
            const errorMessage = errors.reduce(
              (prevValue, { msg, value }) => `${prevValue} ${value}: ${msg}.
              `,
              ''
            );
            setValidationMessage(errorMessage);
          }
        }
      )
      .then(() => {
        setHasConfirmation(true);
      });
  }

  function handleCancel() {
    setNewDeliveryAddress({ street: '', zipCode: '', city: '', country: '' });

    setIsOpen(false);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        TransitionProps={{ onEntering: handleEntering }}
        PaperProps={{
          sx: {
            width: { xs: '80%', md: '50%' },
            padding: '20px',
            backgroundColor: 'white',
          },
        }}
      >
        <DialogTitle sx={{ color: '#0B2A4A', fontWeight: 700 }}>
          Adicionar Morada
        </DialogTitle>

        <Box component='form' onSubmit={handleSubmit}>
          <DialogContent>
            <StyledTextfield
              fullWidth
              multiline
              minRows={2}
              required
              type='text'
              name='street'
              label='Morada Completa'
              value={newDeliveryAddress.street}
              onChange={handleChange}
            />

            <Box
              sx={{
                marginBottom: '10px',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: '0', sm: '10px' },
              }}
            >
              <StyledTextfield
                required
                type='text'
                name='zipCode'
                label='Código Postal'
                value={newDeliveryAddress.zipCode || ''}
                onChange={handleChange}
                sx={{ flex: 1 }}
              />

              <StyledTextfield
                required
                type='text'
                name='city'
                label='Localidade'
                value={newDeliveryAddress.city || ''}
                onChange={handleChange}
                sx={{ flex: 1 }}
              />

              <StyledTextfield
                required
                type='text'
                name='country'
                label='País'
                value={newDeliveryAddress.country || ''}
                onChange={handleChange}
                sx={{ flex: 1 }}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <StyledTextButton
              variant='contained'
              color='secondaryColorButton'
              onClick={handleCancel}
            >
              Cancelar
            </StyledTextButton>

            <StyledTextButton variant='contained' type='submit'>
              Adicionar
            </StyledTextButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Snackbar
        autoHideDuration={4000}
        message={validationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={hasConfirmation}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setHasConfirmation(false);
        }}
        ContentProps={{
          sx: {
            backgroundColor:
              validationMessage === 'Morada adicionada' ? '#4FD290' : '#ff5959',
          },
        }}
      />
    </>
  );
};

export default NewAddressModal;
