import { Box, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledFormInputLabel, StyledTextButton } from '../../styles';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Box
      sx={{
        width: { xs: '80vw', sm: '30vw' },
        marginY: { xs: '20px', sm: '60px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <StyledFormInputLabel gutterBottom sx={{ textAlign: 'center' }}>
        Clientes Profissionais
      </StyledFormInputLabel>

      <LoginForm />

      <Typography
        component={Link}
        to='recuperar-palavra-passe'
        variant='caption'
        sx={{
          fontWeight: 700,
          textAlign: 'center',
          textDecoration: 'none',
          color: '#0b2a4a',
        }}
      >
        Esqueceu-se da palavra-passe?
      </Typography>

      <Divider
        sx={{ width: '100%', marginY: '20px', borderColor: '#0b2a4a' }}
      />

      <Typography sx={{ color: '#0b2a4a', textAlign: 'center' }}>
        Ainda não tenho conta. Pretendo registar-me!
      </Typography>

      <StyledTextButton
        component={Link}
        to='registo-utilizador'
        variant='contained'
      >
        Registar
      </StyledTextButton>
    </Box>
  );
};

export default Login;
