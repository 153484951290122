import { Box, Card, Grid, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProductFromShoppingList } from '../../api/user';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { StyledTextButton } from '../../styles';
import ProductCard from '../ProductCard';

const ShoppingListProducts = ({
  index,
  shoppingLists,
  smallDevicesBreakpoint,
}) => {
  const shoppingListId = shoppingLists[index]._id;

  const [hasConfirmation, setHasConfirmation] = useState(false);

  const [validationMessage, setValidationMessage] = useState('');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  if (!shoppingLists) {
    return null;
  }

  function handleDeleteProductFromShoppingList(productId) {
    deleteProductFromShoppingList(shoppingListId, productId)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setHasConfirmation(true);

        setValidationMessage('Produto eliminado');
      })
      .catch(
        ({
          response: {
            data: { message },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setValidationMessage(message);
          }
        }
      );
  }

  return (
    <Box
      sx={{
        flex: 3,
        marginX: '40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h5' sx={{ color: '#0b2a4a', marginBottom: '40px' }}>
        {shoppingLists[index].name || ''}
      </Typography>

      {shoppingLists[index].products.length === 0 && (
        <Typography>Sem produtos.</Typography>
      )}

      <Grid
        container
        sx={{
          marginX: 0,
          justifyContent: smallDevicesBreakpoint && 'center',
          gap: '18px',
        }}
      >
        {shoppingLists[index].products.map((product) => {
          const {
            variants: [variant],
          } = product;

          if (!Boolean(variant)) return null;

          return (
            <Grid item xs={10} sm={3.7} md={2.8} key={variant._id}>
              <Card
                sx={{
                  position: 'relative',
                  borderRadius: '0',
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <ProductCard
                  product={{
                    ...variant,
                    ...product,
                  }}
                />

                <StyledTextButton
                  onClick={() =>
                    handleDeleteProductFromShoppingList(product._id)
                  }
                  sx={{
                    color: '#b10034',
                    position: 'absolute',
                    bottom: '0',
                    right: '0',
                  }}
                >
                  Eliminar
                </StyledTextButton>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Snackbar
        autoHideDuration={4000}
        message={validationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={hasConfirmation}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setHasConfirmation(false);
        }}
        ContentProps={{
          sx: {
            backgroundColor:
              validationMessage === 'Produto eliminado' ? '#4FD290' : '#ff5959',
          },
        }}
      />
    </Box>
  );
};

export default ShoppingListProducts;
