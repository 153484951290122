import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useState } from 'react';
import { addUser } from '../../api/user';
import { StyledTextButton, StyledTextfield } from '../../styles';

const NewUserModal = ({ isOpen, setIsOpen }) => {
  const [newUserData, setNewUserData] = useState({ email: '', password: '' });

  const [hasConfirmation, setHasConfirmation] = useState(false);

  const [validationMessage, setValidationMessage] = useState('');

  function handleEntering() {
    setNewUserData({});
  }

  function handleChange(e) {
    const { name, value } = e.target;

    setNewUserData((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!newUserData) return;

    addUser(newUserData)
      .then(() => {
        setHasConfirmation(true);

        setValidationMessage('Dados guardados');

        setIsOpen(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status >= 400) {
            const errorMessage = errors.reduce(
              (prevValue, { msg, value }) => `${prevValue} ${value}: ${msg}.
              `,
              ''
            );

            setHasConfirmation(true);

            setValidationMessage(errorMessage);
          }
        }
      );
  }

  function handleCancel(e) {
    setNewUserData({ email: '', password: '' });

    setIsOpen(false);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        TransitionProps={{ onEntering: handleEntering }}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '60%', md: '40%' },
            padding: '20px',
            backgroundColor: 'white',
          },
        }}
      >
        <DialogTitle sx={{ color: '#0B2A4A', fontWeight: 700 }}>
          Criar novo Utilizador
        </DialogTitle>

        <Box component='form' onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText sx={{ fontWeight: 700 }}>
              Vai receber uma confirmação no email indicado.
            </DialogContentText>

            <StyledTextfield
              type='email'
              name='email'
              label='Introduza o email'
              value={newUserData.email || ''}
              onChange={handleChange}
              fullWidth
              required
            />

            <StyledTextfield
              type='password'
              name='password'
              label='Introduza a palavra-passe'
              value={newUserData.password || ''}
              onChange={handleChange}
              fullWidth
              required
            />
          </DialogContent>

          <DialogActions>
            <StyledTextButton
              variant='contained'
              color='secondaryColorButton'
              onClick={handleCancel}
            >
              Cancelar
            </StyledTextButton>

            <StyledTextButton variant='contained' type='submit'>
              Criar Utilizador
            </StyledTextButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Snackbar
        autoHideDuration={6000}
        message={validationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={hasConfirmation}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setHasConfirmation(false);
        }}
        ContentProps={{
          sx: {
            backgroundColor:
              validationMessage === 'Dados guardados' ? '#4FD290' : '#ff5959',
          },
        }}
      />
    </>
  );
};

export default NewUserModal;
