import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUsers } from '../../api/user';
import { StyledTableCell, StyledTextButton } from '../../styles';
import Loading from '../Loading';
import NewUserModal from './NewUserModal';

const AccountUsers = () => {
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const [users, setUsers] = useState([]);

  const [isCreateNewUserOpen, setIsCreateNewUserOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    getUsers()
      .then((users) => {
        setUsers(users);

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        flex: 3,
        marginX: '40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography gutterBottom color='#0b2a4a' fontWeight={700}>
        Lista de Utilizadores
      </Typography>

      {isLoading && <Loading />}

      {!users.length ? (
        <Typography>Não tem outros utilizadores.</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ marginY: '20px' }}>
          <Table size='small' id='users-table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Nome</StyledTableCell>

                <StyledTableCell>Email</StyledTableCell>

                <StyledTableCell>Adicionado em</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users.map(({ name, email, createdAt }, index) => {
                const styledDate = createdAt
                  .substring(0, 10)
                  .split('-')
                  .reverse()
                  .join('-');

                return (
                  <TableRow
                    key={`${name}-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell>{name}</StyledTableCell>

                    <StyledTableCell>{email}</StyledTableCell>

                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {styledDate}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isAdmin && (
        <>
          <StyledTextButton
            onClick={() => setIsCreateNewUserOpen(true)}
            variant='contained'
            color='secondaryColorButton'
            endIcon={<FiPlus size={12} />}
            sx={{ marginLeft: 'auto', marginY: '10px' }}
          >
            Criar novo Utilizador
          </StyledTextButton>

          <NewUserModal
            isOpen={isCreateNewUserOpen}
            setIsOpen={setIsCreateNewUserOpen}
          />
        </>
      )}
    </Box>
  );
};

export default AccountUsers;
