import { Box, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ShoppingListNavMenu from '../components/ShoppingLists/ShoppingListNavMenu';
import ShoppingListProducts from '../components/ShoppingLists/ShoppingListProducts';

const ShoppingListsPage = () => {
  const smallAndMediumDevicesBreakpoint = useMediaQuery('(max-width:768px)');

  const smallDevicesBreakpoint = useMediaQuery('(max-width:766px)');

  const [index, setIndex] = useState(0);

  const shoppingLists = useSelector(
    (state) => state.shoppingLists.shoppingLists
  );

  return (
    <Box
      sx={{
        padding: '40px',
        backgroundColor: '#f7f7f7',
        display: 'flex',
        flexDirection: smallAndMediumDevicesBreakpoint ? 'column' : 'row',
      }}
    >
      <ShoppingListNavMenu
        index={index}
        shoppingLists={shoppingLists}
        setIndex={setIndex}
        smallAndMediumDevicesBreakpoint={smallAndMediumDevicesBreakpoint}
      />

      {!shoppingLists.length ? (
        <Typography variant='h6' sx={{ marginLeft: '20px' }}>
          Não tem listas de compras guardadas.
        </Typography>
      ) : (
        <ShoppingListProducts
          index={index}
          shoppingLists={shoppingLists}
          smallDevicesBreakpoint={smallDevicesBreakpoint}
        />
      )}
    </Box>
  );
};

export default ShoppingListsPage;
