import { Box, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProduct } from '../api/products';
import Loading from '../components/Loading';
import ProductDetails from '../components/Product/ProductDetails';
import ProductImages from '../components/Product/ProductImages';
import ProductRelated from '../components/Product/ProductRelated';
import { resetUserSelection } from '../state/productSlice';
import NotFoundPage from './NotFoundPage';

const ProductPage = () => {
  const smallAndMediumDevicesBreakpoint = useMediaQuery('(max-width:768px)');

  const [product, setProduct] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { productSlug } = useParams();

  useEffect(() => {
    setIsLoading(true);

    getProduct(productSlug)
      .then((newProduct) => {
        setProduct(newProduct);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      })
      .then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productSlug]);

  useEffect(() => {
    dispatch(resetUserSelection());

    return () => {
      dispatch(resetUserSelection());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loading />;

  if (!product) return <NotFoundPage />;

  const { images, related, variants, ...details } = product;

  return (
    <Box
      sx={{
        padding: '40px',
        backgroundColor: '#f7f7f7',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: smallAndMediumDevicesBreakpoint && 'column',
          alignItems: smallAndMediumDevicesBreakpoint && 'center',
          overflowX: 'auto',
        }}
      >
        <ProductImages images={images} />

        <ProductDetails
          details={details}
          variants={variants}
          smallAndMediumDevicesBreakpoint={smallAndMediumDevicesBreakpoint}
        />
      </Box>

      {related && <ProductRelated related={related} />}
    </Box>
  );
};

export default ProductPage;
