import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CompanyInfo from './CompanyInfo';
import PersonalInfo from './PersonalInfo';

const AccountInfo = () => {
  const personalInfo = useSelector((state) => state.user.personalInfo);

  const companyInfo = useSelector((state) => state.user.companyInfo);

  if (!personalInfo || !companyInfo) return null;

  return (
    <Box
      sx={{
        flex: 3,
        marginX: '40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography gutterBottom color='#0b2a4a' fontWeight={700}>
        Informações da Empresa
      </Typography>

      <Typography gutterBottom>
        Consulte os dados da sua empresa e edite a sua informação de contacto.
      </Typography>

      <CompanyInfo />

      <Typography
        gutterBottom
        color='#0b2a4a'
        fontWeight={700}
        marginTop='20px'
      >
        Informações de contacto
      </Typography>

      <PersonalInfo />
    </Box>
  );
};

export default AccountInfo;
