import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../constants/utils";
import { imageOnError } from "../helpers";

const ProductCard = ({ product }) => {
  const { title, brand, description, images, price, variants } = product;

  const min = variants.reduce((min, { price }) => (price < min ? price : min), 9999999);

  const lowercaseImagesUrls = images.map((url) => url.replace(/\b[A-Z]{2,}\b/g, (word) => word.toLowerCase()));

  const parentImage = lowercaseImagesUrls[0];

  return (
    <Card square elevation={0} sx={{ minWidth: "130px", maxWidth: "300px" }}>
      <CardActionArea
        component={Link}
        to={`/produtos/${variants?.[0]?.parent_id}`}
        sx={{ backgroundColor: "white", padding: { xs: "10px", sm: "15px", lg: "20px" } }}
      >
        <CardMedia
          onError={imageOnError}
          component="img"
          image={parentImage}
          alt={title}
          sx={{ maxWidth: "100%", objectFit: "contain", aspectRatio: "1" }}
        />
      </CardActionArea>

      <CardContent sx={{ backgroundColor: "#f7f7f7", paddingX: 0 }}>
        <Typography gutterBottom>{brand}</Typography>

        <Typography noWrap fontWeight={700}>
          {title}
        </Typography>

        {description && (
          <Typography gutterBottom fontWeight={700}>
            {description}
          </Typography>
        )}

        <Typography gutterBottom>{`Código: ${variants?.[0]?.reference}`}</Typography>

        <Typography gutterBottom fontWeight={700}>
          A partir de {formatCurrency(min || price)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
