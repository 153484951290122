import { Grid } from '@mui/material';
import React from 'react';
import ContactCard from './ContactCard';

const StoreLocations = ({ stores }) => {
  return (
    <Grid
      container
      sx={{
        paddingY: '30px',
        gap: '10px',
        justifyContent: 'center',
      }}
    >
      {stores.map((store, index) => (
        <Grid item key={index}>
          <ContactCard store={store} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StoreLocations;
